import { useState, ReactNode, useCallback, useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'

import { SeatsContext } from './seatsContext'
import { useCart } from '../CartContext'
import { TicketType } from '../../../types'
import { CartItemTypeEnum } from '../CartContext/cartContext.type'
import { sleep } from '../../helpers'

const COUNTDOWM_TIME = 60 * 0.25

export const SeatsProvider = ({ children }: { children?: ReactNode }) => {
  const [counter, setCounter] = useState(COUNTDOWM_TIME) // 10 minutes
  const { cart, deleteCartItem } = useCart()
  let interval: string | number | NodeJS.Timer | undefined

  const removeCartItems = useCallback(() => {
    if (cart) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      cart.items.forEach(async (cartItem) => {
        if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET &&
          (cartItem.item as TicketType).event?.seating_chart
        ) {
          void deleteCartItem(cartItem.item)
          await sleep(250)
        }
      })
    }
  }, [cart])

  const startCountDown = useCallback(() => {
    if (!sessionStorage.getItem('seatsio')) {
      toast.warn('The seats will be reserved for 15 minutes.', {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      })
      interval = setInterval(() => {
        setCounter((count) => {
          const newCount = count - 1
          if (newCount === 0) {
            sessionStorage.removeItem('seatsio')
            removeCartItems()
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            sleep(250)
              .then(() => {
                setCounter(COUNTDOWM_TIME)
                clearInterval(interval)
              })
              .catch()
          }
          return newCount
        })
      }, 1000)
    }
  }, [counter])

  const value = useMemo(
    () => ({ startHoldCountDown: startCountDown }),
    [startCountDown]
  )

  return <SeatsContext.Provider value={value}>{children}</SeatsContext.Provider>
}

import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { string, object, ref } from 'yup'
import axios from 'axios'
import { toast } from 'react-toastify'

import { PageTitle } from '../../components'
import { ReactComponent as SVGEyeClose } from '../../assets/icons/eye-closed.svg'

const schema = object().shape({
  password: string().required('New password is a required field'),
  repeatPassword: string()
    .required('Repeat Password is a required field')
    .oneOf([ref('password'), ''], 'Passwords must match'),
})

export const PasswordReset = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const handleSubmit = useCallback(
    async ({ password }: { password: string }) => {
      if (searchParams.get('token')) {
        try {
          const { data } = await axios.post<{ success: boolean }>(
            '/customers/reset-password',
            JSON.stringify({
              e: searchParams.get('e'),
              password,
              token: searchParams.get('token'),
            })
          )

          if (data.success) {
            toast.success(
              'Eh halo! Your password has been changed successfully. You can now login with your new password.'
            )
            navigate('/', { replace: true })
          } else {
            toast.error(
              'Ohno, this password reset link has expired. Let’s try again.'
            )
          }
        } catch {
          toast.error('Error trying to reset password. Please try again later.')
        }
      } else {
        toast.error('Please try again with link in your email.')
      }
    },
    []
  )

  return (
    <>
      <PageTitle title="Password Reset" />
      <Container>
        <Row>
          <Col sm={4} />
          <Col sm={4}>
            <Formik
              validationSchema={schema}
              validateOnChange={false}
              validateOnMount={false}
              validateOnBlur={false}
              onSubmit={async (values) => {
                await handleSubmit(values)
              }}
              initialValues={{
                password: '',
                repeatPassword: '',
              }}
            >
              {(props) => (
                <Form
                  className="my-4 my-md-5 py-4 py-md-5"
                  noValidate
                  onSubmit={props.handleSubmit}
                >
                  <InputGroup className="mb-4">
                    <Form.Control
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      className="bg-white rounded-end rounded-pill border-primary border-end-0 px-3 py-2 fs-7"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.password}
                      value={props.values.password}
                      autoComplete="new-password"
                    />
                    <Button
                      variant="ghost"
                      className="rounded-start rounded-pill border-primary border-start-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <SVGEyeClose />
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <Form.Control
                      id="repeatPassword"
                      type={showRepeatPassword ? 'text' : 'password'}
                      placeholder="Repeat Password"
                      className="bg-white rounded-end rounded-pill border-primary border-end-0 px-3 py-2 fs-7"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.repeatPassword}
                      value={props.values.repeatPassword}
                    />
                    <Button
                      variant="ghost"
                      className="rounded-start rounded-pill border-primary border-start-0"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      <SVGEyeClose />
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.repeatPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <div className="d-flex justify-content-center align-items-center mt-5 ">
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-pill"
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
          <Col sm={4} />
        </Row>
      </Container>
    </>
  )
}

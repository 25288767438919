import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react'
import { toast } from 'react-toastify'

import { ReactComponent as SVGClose } from '../../assets/icons/close.svg'
import { ReactComponent as SVGArrowLeft } from '../../assets/icons/arrow-left.svg'
import './authModal.scss'
import { useAuthentication } from '../../utils/contexts/AuthContext/useAuthentication'
import { LoginForm } from './loginForm'
import { RegistrationForm } from './registrationForm'

export const AuthModal = NiceModal.create(() => {
  const modal = useModal()
  const { token, isRegistered, resetIsRegistered, verifyOtp } =
    useAuthentication()
  const [otp, setOtp] = useState('')
  const [signUpEmail, setSignUpEmail] = useState('')

  const closeModal = async () => {
    if (modal.visible) {
      await modal.hide()
    }
  }

  useEffect(() => {
    if (token.length > 0 && modal.visible) {
      void closeModal()
    }
  }, [token])

  const handleOtpSubmit = async () => {
    if (signUpEmail.length > 0 && otp.length > 0) {
      await verifyOtp({ email: signUpEmail, otp })
    } else {
      toast.warning('Enter verification code again')
    }
  }

  return (
    <Modal
      {...bootstrapDialog(modal)}
      contentClassName="auth-modal border border-2 rounded-5 border-primary"
      backdropClassName="bg-white"
      size="xl"
    >
      <ModalBody className="px-4 py-3 mb-4">
        {isRegistered ? (
          <>
            <Row>
              <Col className="d-flex justify-content-start">
                <Button variant="ghost" onClick={() => resetIsRegistered()}>
                  <SVGArrowLeft />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={3} />
              <Col md={6}>
                <div className="d-flex flex-column justify-content-center">
                  <p className="text-secondary fs-3 text-center">
                    Verification
                  </p>
                  <p className="text-secondary mt-4 fs-7 text-center w-75 mx-auto">
                    A verification code has been sent to the email address
                    provided, please enter it below to continue.
                  </p>
                  <div className="text-center">
                    <input
                      type="text"
                      value={otp}
                      onChange={(event) => setOtp(event.target.value)}
                      className="text-center bg-white rounded-pill border-primary px-3 py-2 fs-7 text-black mt-3"
                      placeholder="Enter verification code"
                    />
                  </div>
                  <Button
                    variant="primary"
                    className="d-block rounded-pill mt-5 w-25 mx-auto"
                    onClick={() => void handleOtpSubmit()}
                  >
                    Proceed
                  </Button>
                </div>
              </Col>
              <Col md={3} />
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button variant="ghost" onClick={() => void closeModal()}>
                  <SVGClose />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="px-md-5 border-1 border-end border-md-end-0 border-md-bottom border-primary pb-5 pb-md-0"
              >
                <h4 className="text-secondary">Login</h4>
                <LoginForm />
              </Col>
              <Col md={6} className="mt-5 mt-md-0 px-md-5">
                <h4 className="text-secondary">Quick Signup</h4>
                <RegistrationForm updateSignUpEmail={setSignUpEmail} />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  )
})

import { useState, ReactNode, useMemo } from 'react'

import { PaymentContext } from './paymentContext'

export const PaymentProvider = ({ children }: { children?: ReactNode }) => {
  const [isPartialPayment, setIsPartialPayment] = useState(false)
  const [donationAmount, setDonationAmount] = useState(5)

  const value = useMemo(
    () => ({
      isPartialPayment,
      setIsPartialPayment,
      donationAmount,
      setDonationAmount,
    }),
    [isPartialPayment, setIsPartialPayment, donationAmount, setDonationAmount]
  )

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  )
}

import { useNavigate } from 'react-router-dom'

import { ExhibitorVariants } from '../../../types/exhibitor.types'
import { ProductType } from '../../../types/product.types'
import './productCard.scss'

export const ProductCard = ({
  product,
  variant,
  isCustomOffer,
}: {
  product: ProductType
  variant: ExhibitorVariants
  isCustomOffer?: boolean
}) => {
  const navigate = useNavigate()
  if (!product) {
    return <></>
  }

  const Wrapper = isCustomOffer ? 'a' : 'div'

  return (
    <Wrapper
      href="mailto:info@redlotusevents.com"
      target="_blank"
      className={`d-flex flex-column border border-2 border-${variant} rounded-3 overflow-hidden w-85 w-md-100 position-relative text-decoration-none${
        product.disabled ? ' cursor-default' : ' cursor-pointer'
      }`}
      onClick={() => {
        if (!isCustomOffer && !product.disabled) {
          navigate('/exhibitor-shop/product', { state: { product, variant } })
        }
      }}
      onKeyUp={() => {
        if (!isCustomOffer && !product.disabled) {
          navigate('/exhibitor-shop/product', { state: { product, variant } })
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={`mx-auto${isCustomOffer ? ' p-4 w-75 w-md-85' : ' w-100'}${
          product.disabled ? ' opacity-40' : ''
        }`}
      >
        <img
          className="w-100"
          src={
            isCustomOffer
              ? '/assets/exhibitor-shop/stalls-grey.png'
              : product?.images[0].url
          }
          alt={product?.images[0].name}
        />
      </div>
      {isCustomOffer ? (
        <p className="email-text position-absolute fs-md-8 p-2 text-center mb-0 text-dark">
          Don&apos;t see a size that suits your brand needs? Talk to us and we
          can help create a space best for you.
          <br />
          No Exhibitors are too big, or too small.
        </p>
      ) : (
        <></>
      )}
      <div
        className={`d-flex justify-content-between bg-${variant} p-2 flex-column flex-md-row${
          product.disabled ? ' opacity-40' : ''
        }`}
      >
        <p className="fs-5 fs-md-6 text-white m-0">
          {isCustomOffer ? ' Enquire Now ' : product.name}
        </p>
        <p className="fs-5 fs-md-6 text-white m-0">
          {isCustomOffer ? ' ' : `£ ${product.price}`}
        </p>
      </div>
      {product.disabled ? (
        <div className={`d-flex justify-content-center bg-${variant} p-2`}>
          <p className="fs-5 fs-md-6 text-white m-0">Sold Out</p>
        </div>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}

import { RootUrl } from './constants'

const NetworkRequest = async <TResponse>(
  url: string,
  config?: RequestInit
): Promise<TResponse> => {
  const completeUrl = `${RootUrl}${url}`

  const response = await fetch(completeUrl, config)

  return (await response.json()) as Promise<TResponse>
}

export const Api = {
  get: <TResponse>(url: string, headers: HeadersInit = {}) =>
    NetworkRequest<TResponse>(url, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        ...headers,
      },
    }),

  // Using `extends` to set a type constraint:
  post: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    headers: HeadersInit = {}
  ) =>
    NetworkRequest<TResponse>(url, {
      method: 'POST',
      body,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        ...headers,
      },
    }),

  put: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    headers?: HeadersInit
  ) =>
    NetworkRequest<TResponse>(url, {
      method: 'PUT',
      body,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        ...headers,
      },
    }),

  delete: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    headers?: HeadersInit
  ) =>
    NetworkRequest<TResponse>(url, {
      method: 'DELETE',
      body,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        ...headers,
      },
    }),
}

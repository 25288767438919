// export const RootUrl = 'http://localhost:8000/api'
export const RootUrl = 'https://admin.redlotusevents.com/api'

export const AUTH_MODAL_ID = 'auth-modal'

export const CART_ITEM_TYPE_TICKET = 'App\\Models\\Ticket'
export const CART_ITEM_TYPE_PRODUCT = 'App\\Models\\Product'

export enum TicketCategories {
  GROUP = 'Group',
  GENERAL_ADMISSION = 'General Admission',
  VIP = 'VIP',
  EARLY_BIRD = 'Early Bird',
}

// export const KIDS_EVENT_IDS = [14, 15, 17]
export const KIDS_EVENT_IDS = [75, 76, 77, 78, 79]
// export const NORMAL_EVENT_IDS = [13, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
export const NORMAL_EVENT_IDS = [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74]

export const KIDS_TICKET_IDS: { [key: number]: Array<number> } = {
  // 14: [47, 55], // Kids between 2 yrs and 10 yrs.
  // 15: [50, 56], // Kids between 2 yrs and 10 yrs.
  // 17: [44, 57], // Kids between 2 yrs and 10 yrs.
  75: [137, 139], // Kids between 3 yrs and 12 yrs, kids below 2 yrs.
  76: [141, 143], // Kids between 3 yrs and 12 yrs, kids below 2 yrs.
  77: [145, 147], // Kids between 3 yrs and 12 yrs, kids below 2 yrs.
  78: [149, 151], // Kids between 3 yrs and 12 yrs, kids below 2 yrs.
  79: [153, 155], // Kids between 3 yrs and 12 yrs, kids below 2 yrs.
}

export const KIDS_BELOW_TWO_YRS_TICKET_IDS: { [key: number]: Array<number> } = {
  // 14: [55], // Kids Garba.
  // 15: [56], // Kids Garba.
  // 17: [57], // Kids Garba.
  // 13: [78], // Season pass.
  // 18: [58], //
  // 19: [60], //
  // 20: [62], //
  // 21: [64], //
  // 22: [77], //
  // 23: [72], //
  // 24: [73], //
  // 25: [74], //
  // 26: [75], //
  // 27: [76], //

  63: [114],
  64: [116],
  65: [118],
  66: [120],
  67: [122],
  68: [124],
  69: [126],
  70: [128],
  71: [130],
  72: [132],
  73: [134],
  74: [136],
  75: [139], // Kids Garba.
  76: [143], // Kids Garba.
  77: [147], // Kids Garba.
  78: [151], // Kids Garba.
  79: [155], // Kids Garba.
}

export const ACCOMPANYING_ADULT_TICKET_IDS: { [key: number]: Array<number> } = {
  // 14: [48], // These are ticket ids for Accompanying parents in for Kids Garba.
  // 15: [51], // These are ticket ids for Accompanying parents in for Kids Garba.
  // 17: [45], // These are ticket ids for Accompanying parents in for Kids Garba.
  // 13: [53], // These are Single Adult tickets. Season Pass.
  // 18: [54], // These are Single Adult tickets
  // 19: [59], // These are Single Adult tickets
  // 20: [61], // These are Single Adult tickets
  // 21: [63], // These are Single Adult tickets
  // 22: [66], // These are Single Adult tickets
  // 23: [67], // These are Single Adult tickets
  // 24: [68], // These are Single Adult tickets
  // 25: [69], // These are Single Adult tickets
  // 26: [70], // These are Single Adult tickets
  // 27: [71], // These are Single Adult tickets

  63: [113], // These are Single Adult tickets
  64: [115], // These are Single Adult tickets
  65: [117], // These are Single Adult tickets
  66: [119], // These are Single Adult tickets
  67: [121], // These are Single Adult tickets
  68: [123], // These are Single Adult tickets
  69: [125], // These are Single Adult tickets
  70: [127], // These are Single Adult tickets
  71: [129], // These are Single Adult tickets
  72: [131], // These are Single Adult tickets
  73: [133], // These are Single Adult tickets
  74: [135], // These are Single Adult tickets
  75: [138], // These are ticket ids for Accompanying parents for Kids Garba.
  76: [142], // These are ticket ids for Accompanying parents for Kids Garba.
  77: [146], // These are ticket ids for Accompanying parents for Kids Garba.
  78: [150], // These are ticket ids for Accompanying parents for Kids Garba.
  79: [154], // These are ticket ids for Accompanying parents for Kids Garba.
}

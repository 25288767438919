import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './footer.scss'

export const Footer = () => {
  return (
    <Container fluid className="footer-container p-0">
      <div className="bg-primary w-100 pb-2">
        <Container fluid="md">
          <Row className="pt-4 pt-lg-5 pb-lg-4 mt-lg-3">
            <Col md={0} lg={1} className="d-none d-lg-block" />
            <Col xs={3} sm={3} md={3} className="d-block d-lg-none" />
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={2}
              className="mb-4 mb-lg-0 d-flex d-md-block align-items-center justify-content-center"
            >
              <div className="logo-wrapper bg-primary rounded-circle p-3 d-flex align-items-center justify-content-center">
                <img
                  src="/assets/logo-white.png"
                  alt="Red Lotus Events"
                  className="w-85 d-block mx-auto"
                />
              </div>
            </Col>
            <Col xs={3} sm={3} md={3} className="d-block d-lg-none" />
            <Col xs={2} sm={2} md={2} lg={2} className="mb-4 mb-lg-0">
              {/* <div className="d-flex flex-column">
                <Link
                  to="/about-us"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  About Us
                </Link>
                <Link
                  to="/get-involved"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Get Involved
                </Link>
                <Link
                  to="/tickets"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Book Tickets
                </Link>
                <Link
                  to="/explore"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Explore
                </Link>
                <Link
                  to="/get-there"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  How to get there
                </Link>
              </div> */}
            </Col>
            <Col xs={8} sm={8} md={8} lg={2} className="mb-4 mb-lg-0">
              <div className="d-flex flex-column align-items-center align-items-md-start">
                <Link
                  to="/privacy-policy"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to="/refund-policy"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  Refund Policy
                </Link>
                <Link
                  to="/faqs"
                  className="text-uppercase fs-7 text-decoration-none p-1 text-light"
                >
                  FAQ&apos;s
                </Link>
              </div>
            </Col>
            <Col xs={2} sm={2} md={2} lg={0} className="d-lg-none" />
            <Col lg={4} className="mb-4 mb-lg-0">
              <div className="d-flex flex-column justify-content-center justify-content-lg-end">
                <a
                  href="mailto:info@redlotusevents.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none align-middle text-center text-lg-end"
                >
                  info@redlotusevents.com
                </a>
                <div className="d-flex social-wrapper justify-content-center justify-content-lg-end mt-3">
                  <a
                    href="https://instagram.com/rangeelugujarat.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="me-3"
                      src="/assets/footer/instagram.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/rangeelugujarat/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="me-3"
                      src="/assets/footer/facebook.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@redlotusevents6771"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/footer/youtube.png"
                      alt=""
                      className="me-3"
                    />
                  </a>
                  <a
                    href="https://wa.me/447729388868"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/footer/whatsapp.png" alt="" />
                  </a>
                </div>
                {/* <div className="mt-5 d-flex flex-row justify-content-center align-items-center justify-content-lg-end">
                  <p className="text-white align-middle text-center text-lg-end fs-6">
                    If you are facing difficulties booking tickets <br />
                    <a
                      href="https://wa.me/4915736944508"
                      target="_blank"
                      rel="noreferrer"
                      className="fw-bold text-white align-middle text-center text-lg-end"
                    >
                      Click here
                    </a>{' '}
                    to contact Tech Support
                  </p>
                </div> */}
              </div>
            </Col>
            <Col md={0} lg={1} className="d-none d-lg-block" />
          </Row>
        </Container>
      </div>
      <div className="bg-light w-100">
        <Container fluid="md">
          <div className="text-dark text-center py-3 fw-bold">
            © 2024 Red Lotus Events | All rights reserved
          </div>
        </Container>
      </div>
    </Container>
  )
}

import { EventType } from './event.types'

type TicketCategory = {
  id: number
  name: string
  description: string
}

export enum TicketStatusEnum {
  HIDDEN = 'hidden',
  ON_SALE = 'on_sale',
  DISPLAY_AS_SOLD_OUT = 'display_as_sold_out',
  ACCESS_CODE_REQUIRED = 'access_code_required',
  DISPLAY_AS_UNAVAILABLE = 'display_as_unavailable',
  ONLY_VISIBLE_TO_ADMIN = 'only_visible_to_admin',
}

export type TicketType = {
  id: number
  name: string
  status: TicketStatusEnum
  description: string
  category: TicketCategory
  price: number
  quantity: number
  group: {
    minimum: number
    maximum: number
  }
  hide_before_sale_start: boolean
  hide_after_sold_out: boolean
  is_transferrable: boolean
  sale_start_at: string | null
  sale_end_at: string | null
  refund_policy: string | null
  cancellation_policy: string | null
  event?: EventType
  seatLabels?: string[]
}

import { useState, useEffect, ReactNode, useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'

import { CartContext } from './cartContext'
import {
  AddUpdateProps,
  ApplyCouponType,
  CartContextType,
  CartDataType,
  CartItemType,
  CartItemTypeEnum,
  CartType,
  LocalCartType,
  RemoveCouponRes,
} from './cartContext.type'
import {
  PrintifyProductType,
  ProductType,
  ShippingCostType,
  TicketType,
} from '../../../types'
import { TabItems } from '../../../pages/ExhibitorShop/Shop/exhibitorShop.types'
import { sleep } from '../../helpers'

export const CartProvider = ({ children }: { children?: ReactNode }) => {
  const [rengeeluAt, setRangeeluAt] = useState<string>()
  const [cart, setCart] = useState<CartType>()
  const [shippingCost, setShippingCost] = useState<ShippingCostType>()
  const [shippingAddressId, setShippingAddressId] = useState<number>()
  const [afterCouponTotal, setAfterCouponTotal] = useState<number>()
  const [appliedCoupon, setAppliedCoupon] = useState<string>()

  const createCart = useCallback(async () => {
    try {
      const { data: response } = await axios.post<CartDataType>('/cart')
      if (response) {
        setCart(response.data)
      }
    } catch (error) {
      toast.error('Failed to create a cart. Please try again later')
    }
  }, [])

  const getCart = useCallback(async () => {
    try {
      const { data: response } = await axios.get<CartDataType>(`/cart`)
      if (response) {
        setCart(() => response.data)
      }
    } catch (error) {
      const errorCode = (error as AxiosError).response?.status
      if (errorCode === 400) {
        void createCart()
      }
    }
  }, [])

  // Get cart when a user is already logged in
  useEffect(() => {
    if (rengeeluAt) {
      void getCart()
    }
  }, [rengeeluAt])

  const updateRangeeluAt = useCallback((token: string) => {
    setRangeeluAt(token)
  }, [])

  const addToCartApi = useCallback(
    async ({
      item,
      itemType,
      newQuantity,
      newPricePerUnit,
      event,
      currentCart,
      variantId,
      seatIds,
    }: AddUpdateProps) => {
      if (currentCart) {
        try {
          const body: any = {
            item_id: item.id,
            item_type: itemType,
            quantity: newQuantity,
            price_per_unit: newPricePerUnit,
            variant_id: variantId,
          }

          if (seatIds) {
            body.object_id = [...seatIds]
          }

          const { data: response } = await axios.post<{ data: CartItemType }>(
            `/cart/${currentCart.id}/items`,
            JSON.stringify(body)
          )

          if (response.data) {
            await getCart()
            await sleep(250)
            // const newCartItems = [
            //   ...currentCart.items,
            //   {
            //     ...response.data,
            //     item: {
            //       ...response.data.item,
            //       category: item.category,
            //       event,
            //     },
            //   },
            // ]
            // setCart({
            //   ...currentCart,
            //   items: [...newCartItems],
            // })

            toast.success(
              `${item.name}${
                seatIds ? ` with ${seatIds.join(', ')} seats` : ''
              } was added to your cart`
            )
          }
        } catch (error) {
          toast.error(`Unable to update quantity of ${item.name}`)
        }
      }
    },
    []
  )

  const addToCart = useCallback(
    async ({
      item,
      event,
      quantity,
      itemType,
      variantId,
      seatIds,
    }: LocalCartType) => {
      // Taking care of Group tickets. This will generate number of tickets that correspond to the group ticket
      // For e.g. Group of 5 ticket will generate 5 tickets
      let newQuantity = quantity
      let newPricePerUnit = Number.parseFloat(`${item.price}`)

      if (itemType === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
        newQuantity = quantity * ((item as TicketType).group.maximum || 1)
        newPricePerUnit = (newPricePerUnit * quantity) / newQuantity
      }

      if (
        itemType === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
        (item as PrintifyProductType).product_type === 'printify'
      ) {
        newPricePerUnit = Number.parseFloat(`${item.price}`)
      }

      if (cart) {
        if (cart.items.length === 0) {
          // If cart is empty, just add any product/ticket to cart
          await addToCartApi({
            item,
            itemType,
            newQuantity,
            newPricePerUnit,
            event,
            currentCart: cart,
            variantId,
            seatIds,
          })
        } else {
          const isTicketInCart = cart.items.some(
            (item) => item.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
          )

          const isExhibitorItemInCart = cart.items.some(
            (item) =>
              item.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
              (item.item as PrintifyProductType).product_type !== 'printify'
          )

          // Either tickets/merchandise or exhibitor items should be in the cart. Not BOTH.
          if (
            isTicketInCart &&
            itemType === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
          ) {
            await addToCartApi({
              item,
              itemType,
              newQuantity,
              newPricePerUnit,
              event,
              currentCart: cart,
              variantId,
              seatIds,
            })
          } else if (
            !isTicketInCart &&
            !isExhibitorItemInCart &&
            itemType === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
            (item as PrintifyProductType).product_type === 'printify'
          ) {
            await addToCartApi({
              item,
              itemType,
              newQuantity,
              newPricePerUnit,
              event,
              currentCart: cart,
              variantId,
              seatIds,
            })
          } else if (
            !isTicketInCart &&
            isExhibitorItemInCart &&
            itemType === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
            (item as PrintifyProductType).product_type !== 'printify'
          ) {
            const category: string = item.category.name
              .toLowerCase()
              .replace(' ', '-')

            const isInternationalInCart = cart.items.some(
              (item) =>
                item.item.category.name.toLowerCase().replace(' ', '-') ===
                TabItems.INTERNATIONAL_STALLS
            )

            const isDomesticInCart = cart.items.some((item) => {
              const itemCategory = item.item.category.name
                .toLowerCase()
                .replace(' ', '-')

              return (
                itemCategory === TabItems.CATERING_STALLS ||
                itemCategory === TabItems.EXHIBITOR_STALLS
              )
            })

            // Either international or domestic stalls are allowed in the cart because of different
            // partial payments.
            if (
              isDomesticInCart &&
              category === TabItems.INTERNATIONAL_STALLS
            ) {
              toast.error(
                'Hey, please remove the Local Exhibitor Stall from the cart before adding an International Exhibitor stall, thank you.',
                { autoClose: 30000 }
              )
            } else if (
              isInternationalInCart &&
              (category === TabItems.CATERING_STALLS ||
                category === TabItems.EXHIBITOR_STALLS)
            ) {
              toast.error(
                'Hey, please remove the International Exhibitor stall from the cart before adding a Local Exhibitor Stall, thank you.',
                { autoClose: 30000 }
              )
            } else {
              await addToCartApi({
                item,
                itemType,
                newQuantity,
                newPricePerUnit,
                event,
                currentCart: cart,
                seatIds,
              })
            }
          } else {
            let message = ''

            if (
              itemType === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
              (item as ProductType).product_type !== 'printify'
            ) {
              message =
                'Hey, please remove Tickets or Merchandise from the cart before adding Exhibitor shop products, thank you.'
            } else if (
              itemType === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
              (item as ProductType).product_type === 'printify'
            ) {
              message =
                'Hey, please remove Tickets or Exhibitor shop products from the cart before adding merchandise, thank you.'
            } else {
              message =
                'Hey, please remove Merchandise or Exhibitor shop products from the cart before adding tickets, thank you.'
            }

            toast.error(message, { autoClose: 30000 })
          }
        }
      }
    },
    [cart]
  )

  const updateItem = useCallback(
    async ({
      item,
      event,
      quantity,
      itemType,
      variantId,
      seatIds,
    }: LocalCartType) => {
      // Taking care of Group tickets. This will generate number of tickets that correspond to the group ticket
      // For e.g. Group of 5 ticket will generate 5 tickets
      let newQuantity = quantity
      let newPricePerUnit = Number.parseFloat(`${item.price}`)

      if (itemType === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
        newQuantity = quantity * ((item as TicketType).group.maximum || 1)
        newPricePerUnit = ((newPricePerUnit || 0) * quantity) / newQuantity
      }

      if (cart) {
        try {
          const body: any = {
            item_id: item.id,
            item_type: itemType,
            quantity: newQuantity,
            price_per_unit: newPricePerUnit,
            variant_id: variantId,
          }

          if (seatIds) {
            body.object_id = [...seatIds]
          }

          const itemToUpdate = cart.items.find(
            (cartItem) => cartItem.item.id === item.id
          )

          if (itemToUpdate) {
            const { data: response } = await axios.put<{ data: CartItemType }>(
              `/cart/${cart.id}/items/${itemToUpdate.id}`,
              JSON.stringify(body)
            )

            if (response) {
              await getCart()
              await sleep(250)
              // const cartItemsLocal = [...cart.items]
              // const updateItemIndex = cartItemsLocal.findIndex(
              //   (item) => item.id === response.data.id
              // )

              // if (updateItemIndex >= 0) {
              //   cartItemsLocal.splice(updateItemIndex, 1, {
              //     ...response.data,
              //     item: {
              //       ...response.data.item,
              //       category: item.category,
              //       event,
              //     },
              //   })
              // }

              // setCart({
              //   ...cart,
              //   items: [...cartItemsLocal],
              // })
            }
          }
        } catch (error) {
          toast.error(`Unable to update quantity of ${item.name}`)
        }
      }
    },
    [cart]
  )

  const deleteCartItem = useCallback(
    async (deleteItem: TicketType | ProductType | PrintifyProductType) => {
      if (cart) {
        const cartItemToDelete = cart.items.find(
          (cartItem) => cartItem.item.id === deleteItem.id
        )
        if (cartItemToDelete) {
          try {
            const { data: response } = await axios.delete<{ success: boolean }>(
              `cart/${cart.id}/items/${cartItemToDelete.id}`
            )

            if (response.success) {
              await getCart()
              toast.success(`${deleteItem.name} is removed from your cart`)
            }
          } catch (error) {
            toast.error(
              `Unable to remove ${deleteItem.name} from your cart. Please try again later`
            )
          }
        }
      }
    },
    [cart]
  )

  const removeCoupon = useCallback(async () => {
    try {
      const { data: removeCouponRes } = await axios.post<RemoveCouponRes>(
        '/cart/promocode-remove'
      )

      if (removeCouponRes.success) {
        setAfterCouponTotal(undefined)
        setAppliedCoupon(undefined)
      }
      return removeCouponRes
    } catch (error) {
      toast.error(`${appliedCoupon} cannot be removed`)

      return {
        success: false,
        message: `${appliedCoupon} cannot be removed`,
      }
    }
  }, [appliedCoupon])

  const applyCoupon = useCallback(async (coupon: string) => {
    if (coupon.length > 0) {
      try {
        const { success } = await removeCoupon()

        if (success) {
          const { data: applyCouponRes } = await axios.post<ApplyCouponType>(
            '/cart/promocode-apply',
            {
              coupan: coupon,
            }
          )

          if (applyCouponRes) {
            await getCart()
            setAfterCouponTotal(applyCouponRes.cart_total)
            setAppliedCoupon(coupon)
            toast.success(`${coupon} successfully applied to the cart`)
          }
        }
      } catch (error) {
        toast.error(`${coupon} cannot be applied`)
      }
    } else {
      setAfterCouponTotal(undefined)
      setAppliedCoupon(undefined)
    }
  }, [])

  const isPrintifyProductInCart = useMemo(() => {
    if (cart) {
      return cart.items.some((cartItem) => {
        return (
          (cartItem.item as PrintifyProductType)?.product_type === 'printify'
        )
      })
    }

    return false
  }, [cart])

  const deleteCart = useCallback(async () => {
    try {
      const { data: response } = await axios.delete<{ success: boolean }>(
        'cart'
      )

      await getCart()
      if (response.success) {
        toast.success('Cart deleted successfully')
      }
    } catch (error) {
      toast.error('Error deleting your cart. Please try again later')
    }
  }, [])

  const providerValue: CartContextType = useMemo(
    () => ({
      getCart,
      createCart,
      addToCart,
      cart,
      updateItem,
      deleteCartItem,
      updateRangeeluAt,
      applyCoupon,
      afterCouponTotal,
      isPrintifyProductInCart,
      shippingCost,
      setShippingCost,
      shippingAddressId,
      setShippingAddressId,
      appliedCoupon,
      setAppliedCoupon,
      removeCoupon,
      deleteCart,
    }),
    [
      getCart,
      createCart,
      addToCart,
      cart,
      updateItem,
      deleteCartItem,
      updateRangeeluAt,
      applyCoupon,
      afterCouponTotal,
      isPrintifyProductInCart,
      shippingCost,
      setShippingCost,
      shippingAddressId,
      setShippingAddressId,
      appliedCoupon,
      setAppliedCoupon,
      removeCoupon,
      deleteCart,
    ]
  )

  return (
    <CartContext.Provider value={providerValue}>
      {children}
    </CartContext.Provider>
  )
}

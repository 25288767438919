import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'

import { AuthContext } from './authContext'
import { RANGEELU_AT, deleteCookie, getCookie, setCookie } from '../../cookie'
import { Api } from '../../networkFetch'
import {
  LoginResponseType,
  LoginUserType,
  RegisterUserResType,
  RegisterUserType,
  VerifyOtpResType,
  VerifyOtpType,
} from './authContext.type'
import { SentenceCase } from '../../helpers'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [rangeeluAt, setRangeeluAt] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [isOtpRegenerated, setIsOtpRegenerated] = useState(false)

  useEffect(() => {
    const rangeeluAtCookie = getCookie(RANGEELU_AT)
    if (rangeeluAtCookie) {
      setRangeeluAt(rangeeluAtCookie)
    }
  }, [])

  useEffect(() => {
    if (rangeeluAt) {
      axios.defaults.headers.common.Authorization = `Bearer ${rangeeluAt}`
    }
  }, [rangeeluAt])

  const login = useCallback(async ({ email, password }: LoginUserType) => {
    if (email && password) {
      try {
        const { data } = await axios.post<LoginResponseType>(
          '/customers/login',
          JSON.stringify({
            email,
            password,
          })
        )
        if (data && data.success && data.token) {
          setCookie(RANGEELU_AT, `${data.token || ''}`)
          setRangeeluAt(`${data.token || ''}`)
          toast.success('SWAGAT CHE! You are now logged in.')
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            toast.error(
              'Email you are trying to login with does not exist. Please register with this email.'
            )
          } else if (error.response?.status === 400) {
            const errorMessage = `${error.response.data.message}`
            toast.error(SentenceCase(errorMessage))
          } else {
            toast.error(
              'Ohno! There was an error trying to log you in. Please try again.'
            )
          }
        } else {
          toast.error(
            'Ohno! There was an error trying to log you in. Please try again.'
          )
        }
      }
    }
  }, [])

  const registerUser = useCallback(
    async ({ name, email, password, phone }: RegisterUserType) => {
      try {
        const { data: registerData } = await axios.post<{
          data: RegisterUserResType
        }>(
          '/customers',
          JSON.stringify({
            name,
            email,
            password,
            phone_number: phone,
          })
        )

        if (registerData.data.id) {
          setIsRegistered(true)
          toast.success(
            'Nearly there! A verification code has been sent to the email address you provided. Please enter the verification code here to complete your registration.'
          )
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data.errors.email) {
            toast.error(
              'An account with this email address already exists, Please login using this email address or reset the password.'
            )
          }
        } else {
          toast.error(
            'Oops, something went wrong with the registration process. Please try again later'
          )
        }
      }
    },
    []
  )

  const resetIsRegistered = useCallback(() => {
    setIsRegistered(false)
  }, [])

  const verifyOtp = useCallback(async ({ email, otp }: VerifyOtpType) => {
    // VerifyOtpResType

    try {
      const verificationData = await Api.post<string, VerifyOtpResType>(
        '/customers/verify-otp',
        JSON.stringify({
          email,
          otp,
        })
      )

      if (verificationData.success) {
        setRangeeluAt(`${verificationData.token || ''}`)
        setCookie(RANGEELU_AT, `${verificationData.token || ''}`)
        toast.success('SWAGAT CHE! You are now logged in.')
      }
    } catch (error) {
      toast.error('Your email address could not be verified.')
    }
  }, [])

  const regenerateOtp = useCallback(async ({ email }: { email: string }) => {
    try {
      const regenerateOtpData = await Api.post<string, { success: boolean }>(
        '/customers/regenerate-token',
        JSON.stringify({
          email,
        })
      )

      if (regenerateOtpData.success) {
        setIsOtpRegenerated(true)
        toast.success('A verification code has been sent to your email.')
      }
    } catch (error) {
      toast.error(
        'Seems resending the verification code failed. How about we try again in a bit.'
      )
    }
  }, [])

  const resetIsOtpRegenerated = useCallback(() => {
    setIsOtpRegenerated(false)
  }, [])

  const logout = useCallback(() => {
    deleteCookie(RANGEELU_AT)
    setRangeeluAt('')
    toast.success('You’ve been logged out')
  }, [])

  const authValue = useMemo(
    () => ({
      token: rangeeluAt,
      login,
      registerUser,
      isRegistered,
      resetIsRegistered,
      verifyOtp,
      regenerateOtp,
      isOtpRegenerated,
      resetIsOtpRegenerated,
      logout,
    }),
    [
      rangeeluAt,
      login,
      registerUser,
      isRegistered,
      resetIsRegistered,
      verifyOtp,
      regenerateOtp,
      isOtpRegenerated,
      resetIsOtpRegenerated,
      logout,
    ]
  )

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

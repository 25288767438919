import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import 'react-toastify/dist/ReactToastify.css'

import './global.scss'
import * as Pages from './pages'
import reportWebVitals from './reportWebVitals'
import {
  DeviceProvider,
  CartProvider,
  AuthProvider,
  LoadingProvider,
} from './utils/contexts'
import { PaymentProvider } from './utils/contexts/PaymentContext'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Pages.Root />}>
      <Route index path="/" element={<Navigate to="/events" />} />
      <Route path="/events" element={<Pages.EventsRoot />}>
        <Route
          index
          path="/events"
          element={<Pages.Events />}
          key={`${new Date()}`}
        />
        <Route
          path="/events/:eventSlug"
          element={<Pages.Tickets />}
          key={`${new Date()}`}
        />
      </Route>
      <Route path="/account" element={<Pages.Account />} />
      <Route path="/reset-password" element={<Pages.PasswordReset />} />
      <Route path="/exhibitor-shop" element={<Pages.ExhibitorRoot />}>
        <Route index path="/exhibitor-shop" element={<Pages.ExhibitorShop />} />
        <Route
          path="/exhibitor-shop/product"
          element={<Pages.ExhibitorProductDetails />}
        />
        <Route
          path="/exhibitor-shop/checkout"
          element={<Pages.CheckoutOverview applyVat />}
        />
      </Route>
      {/* <Route path="/get-in-touch" element={<Pages.ContactUs />} /> */}
      <Route path="/terms-and-conditions" element={<Pages.TermsConditions />} />
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route path="/refund-policy" element={<Pages.RefundTerms />} />
      <Route path="/faqs" element={<Pages.Faq />} />
      {/* <Route path="/products" element={<Pages.PrintifyRoot />}>
        <Route index path="/products" element={<Pages.PrintifyProductList />} />
        <Route
          path="/products/:productId"
          element={<Pages.PrintifyProductDetails />}
        />
      </Route> */}
      <Route path="/checkout" element={<Pages.CheckoutRoot />}>
        <Route
          index
          path="/checkout/overview"
          element={<Pages.CheckoutOverview />}
          key={`${new Date()}`}
        />
        <Route
          index
          path="/checkout/payment"
          element={<Pages.CheckoutSummary />}
          key={`${new Date()}`}
        />
        <Route
          index
          path="/checkout/shipping-address"
          element={<Pages.ShippingAddress />}
        />
        <Route
          index
          path="/checkout/remaining-payment"
          element={<Pages.CheckoutRemainingPayment />}
        />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
)

createRoot(document.getElementById('container') as HTMLElement).render(
  <DeviceProvider>
    <LoadingProvider>
      <AuthProvider>
        <CartProvider>
          <PayPalScriptProvider
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_PROD_CLIENT_ID || '',
              components: 'buttons',
              currency: 'GBP',
            }}
          >
            <PaymentProvider>
              <RouterProvider router={router} />
            </PaymentProvider>
          </PayPalScriptProvider>
        </CartProvider>
      </AuthProvider>
    </LoadingProvider>
  </DeviceProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

import { useContext } from 'react'
import { LoadingContext } from './loadingContext'

export const useLoading = () => {
  const loadingContext = useContext(LoadingContext)
  if (!loadingContext) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return loadingContext
}

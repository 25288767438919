import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Formik, FormikState } from 'formik'
import { string, object, ValidationError } from 'yup'
import { toast } from 'react-toastify'
import axios from 'axios'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { BasketSummary, PageTitle } from '../../../components'
import { FormValues, ShippingAddressType } from './ShippingAddress.types'
import { useCart } from '../../../utils/contexts'
import { ShippingCostType } from '../../../types'

const schema = object().shape({
  addressOne: string().required('Address is required'),
  addressTwo: string().optional(),
  city: string().required('City is required'),
  region: string().required('Region is required'),
  zip: string()
    .matches(
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/,
      'Invalid Zip Code'
    )
    .required('Zip Code is required'),
})

export const ShippingAddress = () => {
  const { setShippingCost, setShippingAddressId } = useCart()
  const navigate = useNavigate()

  const applyShippingAddress = async (values: FormValues) => {
    try {
      const { data: response } = await axios.post<{
        data: ShippingAddressType
      }>(
        '/shipping-addresses',
        JSON.stringify({
          address1: values.addressOne,
          address2: values.addressTwo,
          city: values.city,
          state: values.region,
          zip: values.zip,
          country: 'GB',
        })
      )

      if (response && response.data) {
        setShippingAddressId(response.data.id)
        try {
          const { data: shippingCostRes } = await axios.post<{
            data: ShippingCostType
          }>(
            '/shipping-cost',
            JSON.stringify({
              shipping_address_id: response.data.id,
            })
          )
          if (shippingCostRes.data) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setShippingCost(shippingCostRes.data)
            navigate('/checkout/overview', {
              state: { applyVat: true },
            })
          } else {
            toast.error(
              'Error calculating shipping cost. Please enter correct shipping address within the UK'
            )
          }
        } catch {
          toast.error(
            'Error calculating shipping cost. Please enter correct shipping address within the UK'
          )
        }
      }
    } catch {
      toast.error('Error applying shipping address to the cart.')
    }
  }

  return (
    <>
      <PageTitle title="Shipping Address" />
      <Container>
        <Row>
          <Col lg={1} className="d-none d-lg-block" />
          <Col lg={6}>
            <Formik
              validationSchema={schema}
              validateOnChange={false}
              validateOnMount={false}
              validateOnBlur={false}
              onSubmit={(values) => {
                void applyShippingAddress(values)
              }}
              initialValues={{
                addressOne: '',
                addressTwo: '',
                city: '',
                region: '',
                zip: '',
              }}
            >
              {(props) => (
                <Form
                  className="mt-4 mt-md-5 w-75 w-md-100 mx-auto d-flex flex-column justify-content-between"
                  noValidate
                  onSubmit={props.handleSubmit}
                >
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                      placeholder="Address line 1*"
                      id="addressOne"
                      type="addressOne"
                      value={props.values.addressOne}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.addressOne}
                      autoComplete="address-line1"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.addressOne}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                      placeholder="Address line 2"
                      id="addressTwo"
                      type="addressTwo"
                      value={props.values.addressTwo}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.addressTwo}
                      autoComplete="address-line2"
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.addressTwo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                      placeholder="City*"
                      id="city"
                      type="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.city}
                      autoComplete="address-level2"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Select
                      aria-label="Region*"
                      className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                      id="region"
                      value={props.values.region}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.region}
                      required
                    >
                      <option>Select Region*</option>
                      <option value="BFP">British Forces</option>
                      <option value="ENG">England</option>
                      <option value="NIR">Northern Ireland</option>
                      <option value="SCT">Scotland</option>
                      <option value="WLS">Wales</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.region}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                      placeholder="Postal Code*"
                      id="zip"
                      type="zip"
                      value={props.values.zip}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.errors.zip}
                      autoComplete="postal-code"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zip}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    {props.isSubmitting
                      ? 'Calculating shipping cost . . .'
                      : 'Proceed to Payment'}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
          <Col lg={4}>
            <BasketSummary hideCheckoutButton />
          </Col>
          <Col lg={1} className="d-none d-lg-block" />
        </Row>
      </Container>
    </>
  )
}

import { useCallback, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { ExhibitorShopDesktopProps, TabItems } from './exhibitorShop.types'
import { ExhibitorVariants, ProductType } from '../../../types'
import { ProductCard } from '../../../components'

export const ExhibitorShopDesktop = ({
  products,
}: ExhibitorShopDesktopProps) => {
  const [selectedTab, setSelectedTab] = useState<TabItems>(
    TabItems.EXHIBITOR_STALLS
  )
  const [variant, setVariant] = useState<ExhibitorVariants>('warning')

  const handleTabChange = (key: string | null) => {
    if (key) {
      setSelectedTab(key as TabItems)

      if (key === TabItems.EXHIBITOR_STALLS) {
        setVariant('warning')
      } else if (key === TabItems.CATERING_STALLS) {
        setVariant('success')
      } else if (key === TabItems.INTERNATIONAL_STALLS) {
        setVariant('danger')
      } else if (key === TabItems.ADD_ON) {
        setVariant('primary')
      }
    }
  }

  const renderContent = useCallback(
    (productList: ProductType[], variant: ExhibitorVariants) => {
      return (
        <Row className="my-5">
          {productList.length > 0 &&
            productList.map((product) =>
              product ? (
                <Col sm={4} key={product.id} className="my-5">
                  <ProductCard product={product} variant={variant} />
                </Col>
              ) : (
                <></>
              )
            )}
          {selectedTab !== TabItems.ADD_ON &&
          selectedTab !== TabItems.CATERING_STALLS &&
          productList.length > 0 ? (
            <Col sm={4} className="my-2 my-md-5">
              <ProductCard
                product={productList[0]}
                variant={variant}
                isCustomOffer
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      )
    },
    [selectedTab]
  )

  return (
    <div className="my-4 pt-3 pt-md-3 pt-lg-4 pt-xl-5 d-md-block d-none">
      <Tabs
        activeKey={selectedTab}
        onSelect={handleTabChange}
        defaultActiveKey={TabItems.EXHIBITOR_STALLS}
        fill
      >
        <Tab
          eventKey={TabItems.EXHIBITOR_STALLS}
          title="Exhibitor Stalls"
          tabClassName={`${
            selectedTab === TabItems.EXHIBITOR_STALLS
              ? `text-white bg-${variant}`
              : 'bg-white text-black'
          } border-2 border-start-0 border-top-0 rounded-0 border-bottom border-end border-${variant} fw-semibold fs-5`}
        >
          {renderContent(products[TabItems.EXHIBITOR_STALLS], 'warning')}
        </Tab>
        <Tab
          eventKey={TabItems.CATERING_STALLS}
          title="Catering Stalls"
          tabClassName={`${
            selectedTab === TabItems.CATERING_STALLS
              ? `text-white bg-${variant}`
              : 'bg-white text-black'
          } border-2 border-start-0 border-top-0 rounded-0 border-bottom border-end border-${variant} fw-semibold fs-5`}
        >
          {renderContent(products[TabItems.CATERING_STALLS], 'success')}
        </Tab>
        <Tab
          eventKey={TabItems.INTERNATIONAL_STALLS}
          title="International"
          tabClassName={`${
            selectedTab === TabItems.INTERNATIONAL_STALLS
              ? `text-white bg-${variant}`
              : 'bg-white text-black'
          } border-2 border-start-0 border-top-0 rounded-0 border-bottom border-end border-${variant} fw-semibold fs-5`}
        >
          {renderContent(products[TabItems.INTERNATIONAL_STALLS], 'danger')}
        </Tab>
        <Tab
          eventKey={TabItems.ADD_ON}
          title="Add ons"
          tabClassName={`${
            selectedTab === TabItems.ADD_ON
              ? `text-white bg-${variant}`
              : 'bg-white text-black'
          } border-2 border-start-0 border-top-0 rounded-0 border-bottom border-end-0 border-${variant} fw-semibold fs-5`}
        >
          {renderContent(products[TabItems.ADD_ON], 'primary')}
        </Tab>
      </Tabs>
    </div>
  )
}

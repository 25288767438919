import { PayPalButtons } from '@paypal/react-paypal-js'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { useModal } from '@ebay/nice-modal-react'
import { useSearchParams } from 'react-router-dom'

import {
  CreateOrderErrorType,
  CreateOrderResType,
  PaymentApproveErrorType,
  PaymentApproveResType,
} from './paypalButtonsWrapper.types'
import { useCart, useLoading } from '../../utils/contexts'
import { OrderSuccessModal } from '../OrderSuccessModal'
import { PaymentInfoType } from '../../pages/Checkout/CheckoutRemainingPayment/checkoutRemainingPayment.type'
import { TicketStatusEnum, TicketType } from '../../types'

type CreatePaymentBodyType = {
  coupan?: string
  partial_payment: boolean
  booking_fee: number
  donation: number
  shipping_address_id?: number
  shipping_type: string
  hold_token?: string
}

export const PaypalButtonsWrapper = ({
  isPartialPayment,
  bookingFee = 0,
  donation = 0,
  collectRemainingPayment = false,
}: {
  isPartialPayment: boolean
  bookingFee?: number
  donation?: number
  collectRemainingPayment?: boolean
}) => {
  const { getCart, appliedCoupon, shippingAddressId } = useCart()
  const modal = useModal(OrderSuccessModal)
  const { setIsLoading } = useLoading()
  const [searchParams] = useSearchParams()

  const onCreateOrder = async () => {
    try {
      // Seatsio Hold Token for held seats
      const { holdToken } = JSON.parse(
        sessionStorage.getItem('seatsio') || '{}'
      )
      const createBodyObj: CreatePaymentBodyType = {
        coupan: appliedCoupon,
        partial_payment: isPartialPayment,
        booking_fee: bookingFee || 0,
        donation: donation || 0,
        shipping_address_id: shippingAddressId,
        shipping_type: 'standard',
      }

      if (holdToken) {
        createBodyObj.hold_token = holdToken
      }

      const { data: response } = await axios.post<CreateOrderResType>(
        '/payments/create',
        JSON.stringify(createBodyObj)
      )
      return response.provider_order_id
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        ((error as AxiosError).response?.data as CreateOrderErrorType)
          ?.invalid_items?.length > 0
      ) {
        const invalidTickets = (
          (error as AxiosError).response?.data as CreateOrderErrorType
        )?.invalid_items

        invalidTickets.forEach((invalidTicket) => {
          const ticketName = `${invalidTicket.item.name} (${
            (invalidTicket.item as TicketType).event?.name
          })`

          const errorMessage = `Please remove the following ticket(s) as they are ${
            (invalidTicket.item as TicketType).status ===
            TicketStatusEnum.DISPLAY_AS_SOLD_OUT
              ? 'sold out already'
              : 'unavailable'
          }: ${ticketName}`

          toast.error(errorMessage, { autoClose: false })
        })
      } else {
        toast.error('Unable to create order. Please try again later.')
      }
      return ''
    }
  }

  const onApproveRemainingPayment = async () => {
    try {
      const { data } = await axios.get<PaymentInfoType>(
        `/payments/${searchParams.get('poi')}`
      )
      return data.provider_order_id || ''
    } catch {
      toast.error('Error fetching payment information. Please try again later.')
      return ''
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/require-await
  const onPaymentApprove = async (data: any) => {
    setIsLoading(true)
    try {
      const { data: response } = await axios.post<PaymentApproveResType>(
        '/payments/capture',
        {
          order_id: data.orderID,
        }
      )

      if (response) {
        void getCart()
        setIsLoading(false)
        await modal.show({ orderId: response.order_id })
      }
    } catch (error) {
      if (
        ((error as AxiosError).response?.data as PaymentApproveErrorType)
          ?.message
      ) {
        toast.error(
          `${
            ((error as AxiosError).response?.data as PaymentApproveErrorType)
              ?.message
          }`
        )
      } else {
        toast.error('Transaction not approved. Please try again later.')
      }
      setIsLoading(false)
    }
  }

  return (
    <PayPalButtons
      className="w-100"
      forceReRender={[isPartialPayment, bookingFee, donation]}
      style={{
        layout: 'vertical',
        color: 'gold',
        shape: 'pill',
        tagline: false,
      }}
      createOrder={
        collectRemainingPayment ? onApproveRemainingPayment : onCreateOrder
      }
      onApprove={onPaymentApprove}
    />
  )
}

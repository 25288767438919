import Container from 'react-bootstrap/Container'

import './pageTitle.scss'

export const PageTitle = ({ title }: { title: string }): JSX.Element => {
  return (
    <div className="page-title-container p-0 position-relative my-3 bg-primary">
      <div className="w-100 h-100 d-flex align-items-center">
        <Container>
          <p className="text-center text-white fw-bolder fst-italic m-0">
            {title}
          </p>
        </Container>
      </div>
      <div className="design-bottom position-absolute">
        <img
          src="/assets/elements/design-bottom.png"
          alt=""
          className="w-100"
        />
      </div>
    </div>
  )
}

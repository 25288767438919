import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useModal } from '@ebay/nice-modal-react'

import { ProductType } from '../../../types/product.types'
import { ExhibitorVariants } from '../../../types/exhibitor.types'
import {
  useAuthentication,
  useCart,
  useDeviceType,
} from '../../../utils/contexts'
import { AuthModal } from '../../../components/AuthModal'
import { CartItemTypeEnum } from '../../../utils/contexts/CartContext/cartContext.type'
import { getQuantityPrice } from '../../../utils/helpers'
import { QuantityPicker } from '../../../components'
import { ReactComponent as SVGArrowLeft } from '../../../assets/icons/arrow-left.svg'

type LocationState = {
  product: ProductType
  variant: ExhibitorVariants
}

export const ExhibitorProductDetails = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const modal = useModal(AuthModal)
  const { token } = useAuthentication()
  const { cart, addToCart, updateItem, deleteCartItem } = useCart()
  const { isMobile } = useDeviceType()
  const [product, setProduct] = useState<ProductType>()
  const [initialQuantity, setInitialQuantity] = useState(0)

  useEffect(() => {
    if (state) {
      setProduct((state as LocationState).product)
    }
  }, [state])

  useEffect(() => {
    if (cart) {
      const cartItem = cart.items.find(
        (cartItem) => cartItem.item.id === product?.id
      )

      if (cartItem) {
        const { newQuantity } = getQuantityPrice(cartItem)

        setInitialQuantity(newQuantity)
      } else {
        setInitialQuantity(0)
      }
    }
  }, [cart, product])

  const onAddToCart = useCallback(async () => {
    if (token.length === 0 || cart === undefined) {
      await modal.show()
    } else if (product) {
      void addToCart({
        item: product,
        itemType: CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT,
        quantity: 1,
      })
    }
  }, [token, product, cart])

  const onUpdateCartItem = useCallback(
    (quantity: number) => {
      if (product) {
        if (quantity === 0) {
          void deleteCartItem(product)
          setInitialQuantity(0)
        } else {
          void updateItem({
            item: product,
            itemType: CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT,
            quantity,
          })
        }
      }
    },
    [product]
  )

  return product ? (
    <Container className="my-2 my-md-5">
      <Row>
        <Col>
          <Button
            variant="ghost"
            className="border-0 mt-lg-3 mt-xl-5 py-lg-2"
            onClick={() => navigate(-1)}
          >
            <div className="d-flex align-item-center mb-0 mb-md-3">
              <SVGArrowLeft />
              <div className="ms-2 align-self-center">
                Go back to Exhibitor Shop
              </div>
            </div>
          </Button>
        </Col>
      </Row>
      <div
        className={`p-3 ${
          isMobile ? '' : 'border border-2 '
        }rounded-5 overflow-hidden border-${(state as LocationState).variant}`}
      >
        <Row>
          <Col
            sm={7}
            className={`${isMobile ? 'border border-2 mb-4' : ''} border-${
              (state as LocationState).variant
            } rounded-4`}
          >
            <img
              className="w-100"
              src={product.images[0].url}
              alt={product.images[0].name}
            />
          </Col>
          <Col sm={5} className="d-flex flex-column justify-content-around">
            <div>
              <h4 className="fw-bold m-0">{product.category.name}</h4>
              <p className="fs-5 fw-bold">{product.name}</p>
            </div>
            <div>
              <h4 className="fw-bold">Product Description</h4>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
            <div>
              <p>
                <span className="fw-bold fs-5">£ {product.price}</span>{' '}
                <span className="fs-7">+ VAT</span>
              </p>
              {initialQuantity > 0 ? (
                <div className="w-fit-content">
                  <QuantityPicker
                    min={0}
                    max={99}
                    initialValue={initialQuantity}
                    onChange={(quantity) => onUpdateCartItem(quantity)}
                    wrapperClass="px-3 py-1 py-md-2"
                  />
                </div>
              ) : (
                <Button
                  variant="primary"
                  className="rounded-pill"
                  onClick={() => {
                    void onAddToCart()
                  }}
                >
                  ADD TO CART
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : (
    <></>
  )
}

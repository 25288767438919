import { useState, useEffect, ReactNode } from 'react'

import { DeviceContext } from './deviceContext'
import { DeviceContextType } from './deviceContext.type'
import { useWindowSize } from '../../hooks'

const InitialValue = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
}

export const DeviceProvider = ({ children }: { children?: ReactNode }) => {
  const { width } = useWindowSize()
  const [device, setDevice] = useState<DeviceContextType>(InitialValue)

  useEffect(() => {
    if (width) {
      if (width < 787) {
        setDevice({
          isMobile: true,
          isTablet: false,
          isDesktop: false,
        })
      } else if (width >= 787 && width < 993) {
        setDevice({
          isMobile: false,
          isTablet: true,
          isDesktop: false,
        })
      } else {
        setDevice({
          isMobile: false,
          isTablet: false,
          isDesktop: true,
        })
      }
    }
  }, [width])

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  )
}

import { ReactNode, useMemo, useState } from 'react'

import { LoadingContext } from './loadingContext'

export const LoadingProvider = ({ children }: { children?: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading]
  )

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

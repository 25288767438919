import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { ExhibitorShopMobileProps, TabItems } from './exhibitorShop.types'
import { ExhibitorVariants } from '../../../types'
import { ProductCard } from '../../../components'

export const ExhibitorShopMobile = ({ products }: ExhibitorShopMobileProps) => {
  const [selectedTab, setSelectedTab] = useState<TabItems>(
    TabItems.EXHIBITOR_STALLS
  )

  const [variant, setVariant] = useState<ExhibitorVariants>('warning')

  const handleClick = (key: TabItems) => {
    if (key) {
      setSelectedTab(key)

      if (key === TabItems.EXHIBITOR_STALLS) {
        setVariant('warning')
      } else if (key === TabItems.CATERING_STALLS) {
        setVariant('success')
      } else if (key === TabItems.INTERNATIONAL_STALLS) {
        setVariant('danger')
      } else if (key === TabItems.ADD_ON) {
        setVariant('primary')
      }
    }
  }

  return (
    <div className="my-4 pt-4 d-md-none">
      <div className="d-flex flex-nowrap overflow-auto pills">
        <Button
          variant="outline-primary"
          className={`text-nowrap rounded-pill mx-2${
            selectedTab === TabItems.EXHIBITOR_STALLS
              ? ` bg-${variant} border-${variant} text-white`
              : ''
          }`}
          onClick={() => handleClick(TabItems.EXHIBITOR_STALLS)}
        >
          Exhibitor Stalls
        </Button>
        <Button
          variant="outline-primary"
          className={`text-nowrap rounded-pill mx-2${
            selectedTab === TabItems.CATERING_STALLS
              ? ` bg-${variant} border-${variant} text-white`
              : ''
          }`}
          onClick={() => handleClick(TabItems.CATERING_STALLS)}
        >
          Catering Stalls
        </Button>
        <Button
          variant="outline-primary"
          className={`text-nowrap rounded-pill mx-2${
            selectedTab === TabItems.INTERNATIONAL_STALLS
              ? ` bg-${variant} border-${variant} text-white`
              : ''
          }`}
          onClick={() => handleClick(TabItems.INTERNATIONAL_STALLS)}
        >
          International
        </Button>
        <Button
          variant="outline-primary"
          className={`text-nowrap rounded-pill mx-2${
            selectedTab === TabItems.ADD_ON
              ? ` bg-${variant} border-${variant} text-white`
              : ''
          }`}
          onClick={() => handleClick(TabItems.ADD_ON)}
        >
          Add ons
        </Button>
      </div>
      <Row className="my-5">
        {products[selectedTab].map((product) => (
          <Col xs={6} sm={6} key={product.id} className="my-2 my-md-5">
            <ProductCard product={product} variant={variant} />
          </Col>
        ))}
        {selectedTab !== TabItems.ADD_ON &&
        selectedTab !== TabItems.CATERING_STALLS ? (
          <Col xs={6} sm={6} className="my-2 my-md-5">
            <ProductCard
              product={products[selectedTab][0]}
              variant={variant}
              isCustomOffer
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </div>
  )
}

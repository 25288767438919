import { useContext } from 'react'
import { DeviceContextType } from './deviceContext.type'
import { DeviceContext } from './deviceContext'

export const useDeviceType = (): DeviceContextType => {
  const deviceContent = useContext(DeviceContext)

  if (!deviceContent) {
    throw new Error('useDeviceType was used outside of its provider')
  }

  return deviceContent
}

import { useContext } from 'react'
import { AuthContextType } from './authContext.type'
import { AuthContext } from './authContext'

export const useAuthentication = (): AuthContextType => {
  const authContent = useContext(AuthContext)

  if (!authContent) {
    throw new Error('useAuthentication was used outside of its provider')
  }

  return authContent
}

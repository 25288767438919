import { useEffect } from 'react'

import { useAuthentication, useCart } from '../../utils/contexts'

// This component exists so that token can be passed to cart context-provider
export const CreateCart = () => {
  const { token } = useAuthentication()
  const { updateRangeeluAt } = useCart()

  useEffect(() => {
    if (token) {
      updateRangeeluAt(`${token}`)
    }
  }, [token])

  return <></>
}

import {
  PrintifyProductType,
  ShippingCostType,
} from '../../../types/printifyProduct.types'
import { EventType, TicketType, ProductType } from '../../../types'

export enum CartItemTypeEnum {
  CART_ITEM_TYPE_TICKET = 'App\\Models\\Ticket',
  CART_ITEM_TYPE_PRODUCT = 'App\\Models\\Product',
}

export enum CartItemProductTypeEnum {
  PRODUCT_TYPE_STALL = 'stall',
  PRODUCT_TYPE_ADDON = 'addon',
  PRODUCT_TYPE_PRINTIFY = 'printify',
}

export type LocalCartType = {
  item: TicketType | ProductType | PrintifyProductType
  event?: EventType
  quantity: number
  itemType: CartItemTypeEnum
  variantId?: number
  seatIds?: string[]
}

export type CartItemType = {
  id: number
  item: TicketType | ProductType | PrintifyProductType
  quantity: number
  price_per_unit: number
  item_type: string
  discount_amount: number
  discount_coupan: string | null
  total: number
  object_id?: string[]
}

export type CartType = {
  id: string
  items: CartItemType[]
}

export type CartDataType = {
  data: CartType
}

export type RemoveCouponRes = {
  success: boolean
  message: string
}

export type ApplyCouponType = {
  coupan_applied: string
  cart_total: number
}

export type AddUpdateProps = {
  item: ProductType | TicketType | PrintifyProductType
  itemType: CartItemTypeEnum
  newQuantity: number
  newPricePerUnit: number
  currentCart: CartType
  event?: EventType
  variantId?: number
  seatIds?: string[]
}

export type CartContextType = {
  cart: CartType | undefined
  afterCouponTotal: number | undefined
  addToCart: ({
    item,
    event,
    quantity,
    seatIds,
  }: LocalCartType) => Promise<void>
  createCart: () => Promise<void>
  getCart: () => Promise<void>
  updateItem: ({ item, event, quantity }: LocalCartType) => Promise<void>
  deleteCartItem: (
    deleteItem: TicketType | ProductType | PrintifyProductType
  ) => Promise<void>
  updateRangeeluAt: (token: string) => void
  applyCoupon: (coupon: string) => void
  removeCoupon: () => Promise<RemoveCouponRes>
  isPrintifyProductInCart: boolean
  setShippingCost: React.Dispatch<
    React.SetStateAction<ShippingCostType | undefined>
  >
  shippingCost: ShippingCostType | undefined
  setShippingAddressId: React.Dispatch<React.SetStateAction<number | undefined>>
  shippingAddressId: number | undefined
  appliedCoupon: string | undefined
  setAppliedCoupon: (value: React.SetStateAction<string | undefined>) => void
  deleteCart: () => Promise<void>
}

import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react'
import { useCart } from '../../utils/contexts'

import './seatsModal.scss'
import { SeatObject, SelectedTicketType } from '../../types'
import { SeatingChartProps, SelectedSeats } from './seatsModal.types'

export const SeatingChartModal = NiceModal.create(
  ({ eventId, pricing, onSaveSelectedSeats }: SeatingChartProps) => {
    const modal = useModal()
    const [selectedSeats, setSelectedSeats] = useState<SelectedSeats>()

    const handleSeatSelection = (
      object: SeatObject,
      selectedTicketType: SelectedTicketType
    ) => {
      setSelectedSeats((seats: SelectedSeats | undefined) => {
        const ticketId = selectedTicketType.ticketType

        // const ticketId = object.category.key
        if (seats && seats[ticketId]) {
          let tempSelectedSeats = seats[ticketId]
          const index = tempSelectedSeats.indexOf(object.id)

          if (index > -1) {
            tempSelectedSeats.splice(index, 1)
          } else {
            tempSelectedSeats = [...tempSelectedSeats, object.id]
          }

          return {
            ...seats,
            [ticketId]: [...tempSelectedSeats],
          }
        }

        return {
          ...seats,
          [ticketId]: [object.id],
        }
      })
    }

    return (
      <Modal
        {...bootstrapDialog(modal)}
        contentClassName="border border-2 rounded-5 border-primary"
        backdropClassName="bg-white"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select your seats</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-3 mb-4">
          <div className="seats-wrapper">
            <SeatsioSeatingChart
              workspaceKey={
                process.env.REACT_APP_SEATS_IO_WORKSPACE_PUB_KEY || ''
              }
              event={`${eventId}`}
              region="eu"
              pricing={pricing}
              showLegend
              session="continue"
              priceFormatter={(price) => `£${price}`}
              onObjectSelected={(object, selectedTicketType) => {
                handleSeatSelection(
                  object as unknown as SeatObject,
                  selectedTicketType as unknown as SelectedTicketType
                )
              }}
              onObjectDeselected={(object, selectedTicketType) => {
                handleSeatSelection(
                  object as unknown as SeatObject,
                  selectedTicketType as unknown as SelectedTicketType
                )
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={() => void modal.hide()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => selectedSeats && onSaveSelectedSeats(selectedSeats)}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
)

import { useCallback, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { Formik, FormikState } from 'formik'
import { string, object, ValidationError } from 'yup'
import { toast } from 'react-toastify'
import axios from 'axios'

import { ReactComponent as SVGEyeClose } from '../../assets/icons/eye-closed.svg'
import { useAuthentication } from '../../utils/contexts'

const schema = object().shape({
  password: string().required('Password is a required field'),
  email: string()
    .required('Email is a required field')
    .email('Please give correct email address'),
})

const EmailYupObject = object().shape({
  email: string()
    .required('Email is a required field')
    .email('Please give correct email address'),
})

export const LoginForm = () => {
  const [showLoginPassword, setShowLoginPassword] = useState(false)
  const { login } = useAuthentication()

  const handleForgotPassword = useCallback(
    async (
      formValues: { email: string; password?: string },
      setFieldError: (field: string, message: string | undefined) => void,
      resetForm: (
        nextState?:
          | Partial<
              FormikState<{
                email: string
                password: string
              }>
            >
          | undefined
      ) => void
    ) => {
      let emailError = ''
      let email = ''
      setFieldError('email', '')

      try {
        const a = EmailYupObject.validateSync({ email: formValues.email })
        email = a.email
      } catch (error) {
        emailError = (error as ValidationError).message
        setFieldError('email', emailError)
      }

      if (email.length > 0 && emailError.length === 0) {
        try {
          const { data: response } = await axios.post<{ success: boolean }>(
            '/customers/send-password-reset-link',
            JSON.stringify({ email })
          )

          if (response.success) {
            toast.success('Password reset link has been sent to your email')
            resetForm()
          } else {
            toast.error('Please enter a valid email address')
          }
        } catch {
          toast.error(
            'Whoops! Something went wrong. We were unable to send password verification link, lets try again.'
          )
        }
      }
    },
    []
  )

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        await login(values)
      }}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {(props) => (
        <Form
          className="mt-4 mt-md-5 w-75 w-md-100 d-flex flex-column justify-content-between"
          noValidate
          onSubmit={props.handleSubmit}
        >
          <div className="d-flex flex-column">
            <Form.Group className="mb-4">
              <Form.Control
                className="bg-white rounded-pill border-secondary px-3 py-2 fs-7 text-black"
                placeholder="Enter your email address"
                id="email"
                type="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                isInvalid={!!props.errors.email}
                autoComplete="email"
                required
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <InputGroup className="mb-4" hasValidation>
              <Form.Control
                className="bg-white rounded-end rounded-pill border-secondary border-end-0 px-3 py-2 fs-7 text-black"
                placeholder="Password"
                id="password"
                type={showLoginPassword ? 'text' : 'password'}
                value={props.values.password}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                isInvalid={!!props.errors.password}
                autoComplete="current-password"
                required
              />
              <Button
                variant="ghost"
                className="rounded-start rounded-pill border-secondary border-start-0"
                onClick={() => setShowLoginPassword(!showLoginPassword)}
              >
                <SVGEyeClose />
              </Button>
              <Form.Control.Feedback type="invalid">
                {props.errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <div className="d-flex justify-content-between mt-md-5 pt-md-5">
            <Button
              variant="secondary"
              type="submit"
              disabled={props.isSubmitting}
            >
              Login
            </Button>
            <Button
              variant="ghost"
              className="text-secondary fw-semibold"
              disabled={props.isSubmitting}
              onClick={() => {
                // props.validateField('email')
                void handleForgotPassword(
                  props.values,
                  props.setFieldError,
                  props.resetForm
                )
              }}
            >
              Forgot Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

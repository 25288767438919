import { useEffect, useReducer, useRef } from 'react'

import { RootUrl } from '../constants'

interface State<T> {
  data?: T
  error?: Error
  isLoading: boolean
}

type Cache<T> = { [completeUrl: string]: T }

// discriminated union type
type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error }

export const useFetch = <T = unknown>(
  url: string,
  options?: RequestInit
): State<T> => {
  const cache = useRef<Cache<T>>({})
  const completeUrl = `${RootUrl}${url}`

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    isLoading: false,
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, isLoading: true }
      case 'fetched':
        return { ...initialState, data: action.payload, isLoading: false }
      case 'error':
        return { ...initialState, error: action.payload, isLoading: false }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    // Fetch ONLY IF the url is given
    if (url) {
      cancelRequest.current = false

      const fetchData = async () => {
        dispatch({ type: 'loading' })

        // If a cache exists for this url, return it
        if (cache.current[completeUrl]) {
          dispatch({ type: 'fetched', payload: cache.current[completeUrl] })
          return
        }

        try {
          const response = await fetch(completeUrl, options)
          if (!response.ok) {
            throw new Error(response.statusText)
          }

          const data = (await response.json()) as T
          cache.current[completeUrl] = data

          if (cancelRequest.current) return

          dispatch({ type: 'fetched', payload: data })
        } catch (error) {
          if (cancelRequest.current) return

          dispatch({ type: 'error', payload: error as Error })
        }
      }

      void fetchData()
    }

    // Use the cleanup function for avoiding a possible state update after the component was unmounted
    return () => {
      cancelRequest.current = true
    }
  }, [url])

  return state
}

import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import NiceModal from '@ebay/nice-modal-react'

import * as Components from '../../components'
import { RootUrl } from '../../utils/constants'
import { useLoading } from '../../utils/contexts/LoadingContext/useLoading'
import './root.scss'
import { SeatsProvider } from '../../utils/contexts'

export const Root = () => {
  axios.defaults.baseURL = RootUrl
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.put['Content-Type'] = 'application/json'
  axios.defaults.headers.common.Accept = 'application/json'
  const { isLoading } = useLoading()

  return (
    <NiceModal.Provider>
      <SeatsProvider>
        <Components.ScrollToTop />
        <div className="content-wrapper position-relative">
          {isLoading && <Components.LoadingIndicator />}
          <Components.CreateCart />
          <ToastContainer position="bottom-left" />
          <Components.Header />
          <Outlet />
          <Components.Footer />
        </div>
      </SeatsProvider>
    </NiceModal.Provider>
  )
}

import { useContext } from 'react'
import { CartContextType } from './cartContext.type'
import { CartContext } from './cartContext'

export const useCart = (): CartContextType => {
  const cartContent = useContext(CartContext)

  if (!cartContent) {
    throw new Error('useCartContext was used outside of its provider')
  }

  return cartContent
}

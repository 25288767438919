import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { Formik } from 'formik'
import { string, object, ref } from 'yup'

import { ReactComponent as SVGEyeClose } from '../../assets/icons/eye-closed.svg'
import { useAuthentication } from '../../utils/contexts'

const schema = object().shape({
  name: string().required('Fullname is a required field'),
  password: string().required('Password is a required field'),
  phone: string().required('Phone is a required field'),
  email: string()
    .required('Email is a required field')
    .email('Enter correct email address'),
  repeatPassword: string()
    .required('Repeat Password is a required field')
    .oneOf([ref('password'), ''], 'Passwords must match'),
})

export const RegistrationForm = ({
  updateSignUpEmail,
}: {
  updateSignUpEmail: (email: string) => void
}) => {
  const [showSignupPassword, setShowSignupPassword] = useState(false)
  const [showRepeatSignPassword, setShowRepeatSignPassword] = useState(false)
  const { registerUser } = useAuthentication()

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        updateSignUpEmail(values.email)
        await registerUser(values)
      }}
      initialValues={{
        name: '',
        password: '',
        phone: '',
        email: '',
        repeatPassword: '',
      }}
    >
      {(props) => (
        <Form
          className="mt-4 mt-md-5 w-75 w-md-100"
          noValidate
          onSubmit={props.handleSubmit}
        >
          <Form.Group className="mb-4">
            <Form.Control
              id="name"
              type="text"
              placeholder="Enter your full name"
              className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              isInvalid={!!props.errors.name}
              value={props.values.name}
              autoComplete="name"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              id="email"
              type="email"
              placeholder="Enter your email address"
              className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              isInvalid={!!props.errors.email}
              value={props.values.email}
              autoComplete="email"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              id="phone"
              type="phone"
              placeholder="Phone No."
              className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              isInvalid={!!props.errors.phone}
              value={props.values.phone}
              autoComplete="tel"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
          <InputGroup className="mb-4">
            <Form.Control
              id="password"
              type={showSignupPassword ? 'text' : 'password'}
              placeholder="Password"
              className="bg-white rounded-end rounded-pill border-secondary border-end-0 px-3 py-2 fs-7"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              isInvalid={!!props.errors.password}
              value={props.values.password}
              autoComplete="new-password"
            />
            <Button
              variant="ghost"
              className="rounded-start rounded-pill border-secondary border-start-0"
              onClick={() => setShowSignupPassword(!showSignupPassword)}
            >
              <SVGEyeClose />
            </Button>
            <Form.Control.Feedback type="invalid">
              {props.errors.password}
            </Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-4">
            <Form.Control
              id="repeatPassword"
              type={showRepeatSignPassword ? 'text' : 'password'}
              placeholder="Repeat Password"
              className="bg-white rounded-end rounded-pill border-secondary border-end-0 px-3 py-2 fs-7"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              isInvalid={!!props.errors.repeatPassword}
              value={props.values.repeatPassword}
            />
            <Button
              variant="ghost"
              className="rounded-start rounded-pill border-secondary border-start-0"
              onClick={() => setShowRepeatSignPassword(!showRepeatSignPassword)}
            >
              <SVGEyeClose />
            </Button>
            <Form.Control.Feedback type="invalid">
              {props.errors.repeatPassword}
            </Form.Control.Feedback>
          </InputGroup>
          <div className="d-flex justify-content-between mt-5">
            <Button variant="secondary" type="submit">
              Signup
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import { TicketType } from '../../types'
import {
  CartItemType,
  CartItemTypeEnum,
} from '../contexts/CartContext/cartContext.type'

export const getQuantityPrice = (cartItem: CartItemType) => {
  let newQuantity = cartItem.quantity
  let newPricePerUnit = Number.parseFloat(`${cartItem.price_per_unit}`)

  if (cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
    newQuantity =
      cartItem.quantity / ((cartItem.item as TicketType).group.maximum || 1)
    newPricePerUnit = (newPricePerUnit || 0) * cartItem.quantity
  }

  return { newQuantity, newPricePerUnit }
}

import { ProductType } from '../../../types/product.types'

export enum TabItems {
  EXHIBITOR_STALLS = 'exhibitor-stalls',
  CATERING_STALLS = 'catering-stalls',
  INTERNATIONAL_STALLS = 'international-stalls',
  ADD_ON = 'add-ons',
}

export type ProductListType = {
  [key in TabItems]: ProductType[]
}

export type ExhibitorShopDesktopProps = {
  products: ProductListType
}

export type ExhibitorShopMobileProps = {
  products: ProductListType
}

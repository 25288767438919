import { useContext } from 'react'
import { TermsConditionsContext } from './termsContext'

export const useTermsConditions = () => {
  const termsConditionsContext = useContext(TermsConditionsContext)
  if (!termsConditionsContext) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return termsConditionsContext
}

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Moment from 'react-moment'

import { MyOrdersLocal } from '../../../pages/Account/account.type'

export const ShopMobile = ({
  orders,
  sendEmail,
}: {
  orders: MyOrdersLocal[]
  sendEmail: (orderId: string) => void
}) => {
  return orders && orders.length > 0 ? (
    <>
      {orders.map((order) => (
        <Card
          key={`${order.id}_${order.created_at}_${order.itemId}`}
          className="p-3 mb-3"
        >
          <Row className="d-flex">
            <Col className="d-flex flex-column justify-content-start align-items-start">
              <p className="mb-2 fw-semibold">{order.itemName}</p>
              <Moment format="Do MMMM YYYY">{order.created_at}</Moment>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Button
                variant="primary"
                className="rounded-pill"
                onClick={() => sendEmail(`${order.id}`)}
              >
                Resend summary
              </Button>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  ) : (
    <p className="m-2">You have not made any shop purchases</p>
  )
}

/* eslint-disable react/no-unescaped-entities */

import Container from 'react-bootstrap/Container'
import { PageTitle } from '../../components'

export const RefundTerms = () => {
  return (
    <>
      <PageTitle title="Refund Policy" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto">
          <br />
          1. Where an Event Ticket is purchased on this website, these Refund
          Terms shall apply in their entirety (in addition to the above General
          Terms and Entry Conditions).
          <br />
          <br />
          2. Where any of the following are purchased on a third party ticketing
          platform, the terms and conditions of that third party (“
          <strong>Third Party Terms</strong>”) in addition to the General Terms,
          Entry Conditions, or any other applicable terms. Where those Third
          Party Terms reference the terms and conditions of the Organiser in
          relation to refunds, cancellation, abandonment, curtailment and/or
          postponement these Refund Terms shall apply to the extent referenced
          therein, and in the event of conflict between the Third Party Terms
          and these Refund Terms, the Third Party Terms shall prevail. Please
          contact the ticketing agent (as applicable) with questions relating to
          all such matters.
          <br />
          <br />
          3. If the Event is postponed (whether in its entirety or if the
          relevant day of the Event to which your Event Ticket relates, is
          postponed), your Event Ticket and any Add Ons (if applicable) will be
          valid for the rescheduled Event. Where you are unable to attend the
          rescheduled Event or where you choose not to do so, you will be
          entitled to a full refund of the face value of the Event Ticket and
          any Add Ons (if applicable), but no refund will be given in respect of
          any other expenditure whatsoever.
          <br />
          <br />
          4. If the Event is cancelled (whether in its entirety or if the
          relevant day of the Event to which your Event Ticket relates, is
          cancelled) prior to the opening of the Event day (as applicable), you
          shall be entitled to a full refund of the face value of the Event
          Ticket and any Add Ons (if applicable) but no refund will be given in
          respect of any other expenditure whatsoever.
          <br />
          <br />
          5. If the day of the Event to which your Event Ticket relates is
          curtailed more than 50% into the scheduled headline performance, no
          refunds shall be given in respect of any Event Ticket or Add Ons.
          Where less than 50% of the headline performance has taken place, the
          following provisions shall apply:
          <br />
          <br />
          <span className="ms-3">
            5.1. For General Tickets: you shall be entitled to a full refund of
            the face value of the Event Ticket but no refund shall be available
            in respect of any Add Ons (if applicable)
          </span>
          <br />
          <br />
          <span className="ms-3">
            5.2. For Premium Tickets: you shall be entitled to a refund of the
            face value of a top price General Ticket (as applicable to the
            curtailed performance) but no refund shall be given in respect of
            the hospitality element of the Premium Ticket.
          </span>
          <br />
          <br />
          <span className="ms-3">
            5.3. Where any curtailed Event is rescheduled (in whole or in part),
            the provisions of Clause 3 of these Refund Terms shall apply.
          </span>
          <br />
          <br />
          6. If any of these Refund Terms should be determined to be illegal,
          invalid or otherwise unenforceable by reason of the law of any state
          or country in which these terms and conditions are intended to be
          effective, then to the extent and within the jurisdiction in which
          that term or condition is illegal, invalid or unenforceable, it shall
          be severed and deleted from that term and the remaining terms and
          conditions shall survive and continue to be binding and enforceable.
          <br />
          <br />
          7. The Organiser expressly reserves the right to change and/or amend
          these Refund Terms from time to time.
          <br />
          <br />
        </p>
      </Container>
    </>
  )
}

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { toast } from 'react-toastify'
import axios from 'axios'

import { PageTitle } from '../../components'
import './account.scss'
import {
  AccountType,
  MyOrdersCategoryLocal,
  MyOrdersType,
} from './account.type'
import { useAuthentication, useDeviceType } from '../../utils/contexts'
import {
  AccountComponent,
  ExhibitorDesktop,
  ExhibitorMobile,
  ShopDesktop,
  ShopMobile,
  TicketsDesktop,
  TicketsMobile,
} from '../../components/Account'
import { CartItemTypeEnum } from '../../utils/contexts/CartContext/cartContext.type'

enum Tabs {
  ACCOUNT = 'Account',
  TICKETS = 'Ticket Purchase',
  SHOP = 'Shop Purchase',
  OTHER = 'Other',
  LOGOUT = 'Logout',
}

export const Account = () => {
  const [tab, setTab] = useState('Account')
  const [user, setUser] = useState<AccountType>()
  const [orders, setOrders] = useState<MyOrdersCategoryLocal>()
  const { logout } = useAuthentication()
  const navigate = useNavigate()
  const { isMobile } = useDeviceType()

  const fetchUser = async () => {
    try {
      const { data: userData } = await axios.get<AccountType>('/user')

      if (userData) {
        setUser(userData)
      }
    } catch (error) {
      toast.error('Error receiving account information. Please try again later')
    }
  }

  const fetchOrders = async () => {
    try {
      const { data: ordersData } = await axios.get<{
        data: MyOrdersType[]
      }>('/orders')

      if (ordersData) {
        const newOrdersData: MyOrdersCategoryLocal = {}

        ordersData.data.forEach((order) => {
          order.items.forEach((orderItem) => {
            if (orderItem.item) {
              const item = {
                itemName: orderItem.item.name,
                itemId: orderItem.id,
                quantity: orderItem.quantity,
                id: order.id,
                created_at: order.created_at,
              }
              if (
                newOrdersData[
                  orderItem.item_type || CartItemTypeEnum.CART_ITEM_TYPE_TICKET
                ]
              ) {
                newOrdersData[
                  orderItem.item_type || CartItemTypeEnum.CART_ITEM_TYPE_TICKET
                ].push(item)
              } else {
                newOrdersData[
                  orderItem.item_type || CartItemTypeEnum.CART_ITEM_TYPE_TICKET
                ] = [item]
              }
            }
          })
        })
        setOrders({ ...newOrdersData })
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Error receiving account information. Please try again later')
    }
  }

  useEffect(() => {
    void fetchUser()
    void fetchOrders()
  }, [])

  useEffect(() => {
    if (tab === Tabs.LOGOUT) {
      logout()
      navigate('/')
    }
  }, [tab])

  const handleSendTickets = async (orderId: string) => {
    try {
      const { data: resendResponse } = await axios.post<{ success: boolean }>(
        `/tickets/resend/${orderId}`
      )
      if (resendResponse) {
        if (resendResponse.success) {
          toast.success('Woohoo! An email with your tickets has been sent!')
        } else {
          toast.error(
            "Ohno! Seems there's been an error sending an email with your tickets. Please try again later"
          )
        }
      }
    } catch {
      toast.error(
        'Failed to send an email with your tickets. Please try again later'
      )
    }
  }

  return (
    <>
      <PageTitle title="Account" />
      <Container className="py-4 account mt-md-3 mt-lg-4 mt-xl-5">
        <Tab.Container
          id="left-tabs-example"
          activeKey={tab}
          defaultActiveKey="Account"
          onSelect={(name) => setTab(name || '')}
        >
          <Row className="mt-md-1 mt-lg-2 mt-xl-3">
            <Col sm={1} />
            <Col
              sm={2}
              className="border-1 border-md-end-0 border-end border-dark"
            >
              <Nav className={isMobile ? 'flex-row' : 'flex-column'}>
                <Nav.Item
                  className={
                    isMobile
                      ? 'w-50 text-center border-1 border-end border-bottom border-dark py-2'
                      : ''
                  }
                >
                  <Nav.Link eventKey={Tabs.ACCOUNT} className="text-dark">
                    Account
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    isMobile
                      ? 'w-50 text-center border-1 border-bottom border-dark py-2'
                      : ''
                  }
                >
                  <Nav.Link eventKey={Tabs.TICKETS} className="text-dark">
                    {Tabs.TICKETS}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    isMobile
                      ? 'w-50 text-center border-1 border-end border-bottom border-dark py-2'
                      : ''
                  }
                >
                  <Nav.Link eventKey={Tabs.SHOP} className="text-dark">
                    {Tabs.SHOP}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    isMobile
                      ? 'w-50 text-center border-1 border-bottom border-dark py-2'
                      : ''
                  }
                >
                  <Nav.Link eventKey={Tabs.OTHER} className="text-dark">
                    {Tabs.OTHER}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    isMobile
                      ? 'w-50 text-center py-2 border-1 border-end border-dark'
                      : ''
                  }
                >
                  <Nav.Link eventKey={Tabs.LOGOUT} className="text-dark">
                    {Tabs.LOGOUT}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col
              sm={8}
              className={`ms-md-5 mt-4 mt-md-0 ${
                isMobile ? 'border-0 pt-4' : ''
              } border-dark`}
            >
              <Tab.Content>
                <Tab.Pane eventKey={Tabs.ACCOUNT} title="Account">
                  <AccountComponent user={user} />
                </Tab.Pane>
                <Tab.Pane eventKey={Tabs.TICKETS} title="Ticket Purchase">
                  {isMobile ? (
                    <TicketsMobile
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_TICKET]
                          : []
                      }
                      sendTickets={(orderId) => void handleSendTickets(orderId)}
                    />
                  ) : (
                    <TicketsDesktop
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_TICKET]
                          : []
                      }
                      sendTickets={(orderId) => void handleSendTickets(orderId)}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={Tabs.SHOP} title="Shop Purchase">
                  {isMobile ? (
                    <ShopMobile
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT] ||
                            []
                          : []
                      }
                      sendEmail={(orderId) => void handleSendTickets(orderId)}
                    />
                  ) : (
                    <ShopDesktop
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT] ||
                            []
                          : []
                      }
                      sendEmail={(orderId) => void handleSendTickets(orderId)}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={Tabs.OTHER} title="Other">
                  {isMobile ? (
                    <ExhibitorMobile
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT] ||
                            []
                          : []
                      }
                      sendEmail={(orderId) => void handleSendTickets(orderId)}
                    />
                  ) : (
                    <ExhibitorDesktop
                      orders={
                        orders
                          ? orders[CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT] ||
                            []
                          : []
                      }
                      sendEmail={(orderId) => void handleSendTickets(orderId)}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col sm={1} />
          </Row>
        </Tab.Container>
      </Container>
    </>
  )
}

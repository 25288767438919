/* eslint-disable react/no-unescaped-entities */

import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

import { PageTitle } from '../../components'

export const TermsConditions = () => {
  return (
    <>
      <PageTitle title="Terms and Conditions" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto terms-conditions">
          <br />
          <strong>GENERAL TERMS</strong>
          <br />
          1. Event Definition: "<strong>Event</strong>" refers to any event for
          which tickets are being sold.
          <br />
          <br />
          2. Organiser: The Event is organised and managed by RL Events, Trading
          as Red Lotus Events.
          <br />
          <br />
          3. Ticket Purchase:
          <ul className="m-0">
            <li>
              All tickets must be purchased through the Red Lotus Events Ticket
              portal, tickets purchased from anywhere else will not be
              considered valid.
            </li>
            <li>
              The purchase of a ticket constitutes acceptance of these terms and
              conditions.
            </li>
          </ul>
          <br />
          4. Admission:
          <ul className="m-0">
            <li>A valid ticket is required to enter the Event.</li>
            <li>
              Tickets must be presented upon request and are subject to
              inspection.
            </li>
            <li>
              The Organiser reserves the right to refuse admission to any ticket
              holder.
            </li>
            <li>
              Bag searches and security checks may be conducted depending on the
              type of event.
            </li>
          </ul>
          <br />
          5. Ticket Holder Responsibilities:
          <ul className="m-0">
            <li>
              Check tickets upon receipt for accuracy; errors cannot always be
              rectified.
            </li>
            <li>
              Retain tickets in a safe place. The Organiser is not responsible
              for lost, stolen, or damaged tickets.
            </li>
          </ul>
          <br />
          6. Ticket Use:
          <ul className="m-0">
            <li>
              Tickets are for personal use only and must not be resold or
              transferred.
            </li>
            <li>
              Tickets may not be used for promotional purposes without the
              Organiser's written consent.
            </li>
            <li>
              Any ticket found to be resold, transferred, or used in violation
              of these terms will be voided.
            </li>
          </ul>
          <br />
          7. Refunds and Exchanges:
          <ul className="m-0">
            <li>
              All ticket sales are final. No refunds, cancellations, or
              exchanges are permitted unless the Event is cancelled or
              rescheduled.
            </li>
            <li>
              In the event of cancellation or rescheduling, the Organiser will
              outline the refund process.
            </li>
          </ul>
          <br />
          8. Event Changes:
          <ul className="m-0">
            <li>
              The Organiser reserves the right to alter the Event schedule,
              including performers, timings, and venue, without notice or
              obligation for refund.
            </li>
          </ul>
          <br />
          9. Venue Rules:
          <ul className="m-0">
            <li>
              Ticket holders must comply with all venue rules and regulations.
            </li>
            <li>
              The Organiser and venue management reserve the right to conduct
              security searches.
            </li>
          </ul>
          <br />
          10. Prohibited Items and Behavior:
          <ul className="m-0">
            <li>
              Prohibited items include, but are not limited to, weapons, and
              illegal substances.
            </li>
            <li>
              Anti-social behaviour, intoxication, or any action that disrupts
              the Event may result in removal from the venue without refund.
            </li>
            <li>
              Any antisocial behaviour towards staff, volunteers, or management
              will result in immediate removal from the premises and the ticket
              being voided without a refund.
            </li>
          </ul>
          <br />
          11. Liability:
          <ul className="m-0">
            <li>
              Attendance is at the ticket holder's own risk. The Organiser is
              not liable for any loss, injury, or damage, except in cases of
              negligence.
            </li>
          </ul>
          <br />
          12. Data Protection:
          <ul className="m-0">
            <li>
              Personal data provided during ticket purchase will be processed by
              applicable data protection laws and the Organiser's{' '}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <br />
          13. Filming and Photography:
          <ul className="m-0">
            <li>
              By attending the event, ticket holders consent to be photographed,
              filmed, or recorded. The Organiser retains all rights to such
              media for promotional purposes.
            </li>
          </ul>
          <br />
          14. Unforeseeable Circumstances:
          <ul className="m-0">
            <li>
              The Organiser is not responsible for cancellations, delays, or
              changes due to circumstances beyond their control, such as natural
              disasters and/or government restrictions.
            </li>
          </ul>
          <br />
          15. Governing Law:
          <ul className="m-0">
            <li>
              These terms and conditions are governed by the laws of the
              jurisdiction in which the Event is held. Any disputes will be
              subject to the exclusive jurisdiction of the local courts.
            </li>
          </ul>
          <br />
          16. Amendments:
          <ul className="m-0">
            <li>
              The Organiser reserves the right to amend these terms and
              conditions at any time. Any changes will be communicated through
              official channels.
            </li>
          </ul>
          <br />
          <strong>Miscellaneous</strong>
          <br />
          1. Acceptance of Terms:
          <ul className="m-0">
            <li>
              Purchase and use of a ticket constitute acceptance of these terms
              and conditions.
            </li>
          </ul>
          <br />
          2. Severability:
          <ul className="m-0">
            <li>
              If any provision of these terms is deemed unenforceable, the
              remaining provisions will remain in effect.
            </li>
          </ul>
          <br />
          3. Entire Agreement:
          <ul className="m-0">
            <li>
              These terms and conditions constitute the entire agreement between
              the Organiser and the ticket holder.
            </li>
          </ul>
          <br />
        </p>
      </Container>
    </>
  )
}

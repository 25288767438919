import { useCallback, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { Formik, FormikHelpers } from 'formik'
import { string, object, ref } from 'yup'
import axios from 'axios'
import { toast } from 'react-toastify'

import { AccountType } from '../../../pages/Account/account.type'
import { useDeviceType } from '../../../utils/contexts'
import { ReactComponent as SVGEyeClose } from '../../../assets/icons/eye-closed.svg'
import './account.scss'

const schema = object().shape({
  newPassword: string().required('New password is a required field'),
  newPasswordConfirm: string()
    .required('Repeat Password is a required field')
    .oneOf([ref('newPassword'), ''], 'Passwords must match'),
  oldPassword: string().required('Old password is a required field'),
})

type FormData = {
  newPassword: string
  newPasswordConfirm: string
  oldPassword: string
}

export const AccountComponent = ({ user }: { user?: AccountType }) => {
  const { isMobile } = useDeviceType()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)

  const changePassword = useCallback(
    async (values: FormData, formikBag: FormikHelpers<FormData>) => {
      try {
        const body = {
          password: values.newPassword,
          password_confirmation: values.newPasswordConfirm,
          old_password: values.oldPassword,
        }
        const { data } = await axios.post<{ success: boolean }>(
          '/change-password',
          JSON.stringify(body)
        )

        if (data.success) {
          toast.success('Yay! Password successfully changed')
          formikBag.resetForm()
        }
      } catch {
        toast.error('Whoops! Something went wrong. Please try again later')
      }
    },
    []
  )

  return (
    <>
      <Row className="mt-md-5">
        <Col sm={5}>
          <div
            className={`border rounded-pill p-2 p-md-3 mb-4 ${
              isMobile ? 'fs-7' : 'fs-6'
            }`}
          >
            {user?.name}
          </div>
        </Col>
        <Col sm={5}>
          <div
            className={`border rounded-pill p-2 p-md-3 mb-4 ${
              isMobile ? 'fs-7' : 'fs-6'
            }`}
          >
            {user?.email}
          </div>
        </Col>
        <Col sm={4}>
          <div
            className={`border rounded-pill p-2 p-md-3 mb-4 ${
              isMobile ? 'fs-7' : 'fs-6'
            }`}
          >
            {user?.phone_number}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={5}>
          <p className="fw-bold fs-7 mt-3">Change Password</p>
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnMount={false}
            validateOnBlur={false}
            onSubmit={async (values, formikBag) => {
              await changePassword(values, formikBag)
            }}
            initialValues={{
              newPassword: '',
              newPasswordConfirm: '',
              oldPassword: '',
            }}
          >
            {(props) => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <InputGroup className="mb-4">
                  <Form.Control
                    id="oldPassword"
                    type={showOldPassword ? 'text' : 'password'}
                    placeholder="Old Password"
                    className="bg-white rounded-end rounded-pill border-end-0 px-3 py-2 fs-7"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    isInvalid={!!props.errors.oldPassword}
                    value={props.values.oldPassword}
                    autoComplete="password"
                  />
                  <Button
                    variant="ghost"
                    className="rounded-start rounded-pill border-start-0"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    <SVGEyeClose />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {props.errors.oldPassword}
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-4">
                  <Form.Control
                    id="newPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="Repeat Password"
                    className="bg-white rounded-end rounded-pill border-end-0 px-3 py-2 fs-7"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    isInvalid={!!props.errors.newPassword}
                    value={props.values.newPassword}
                  />
                  <Button
                    variant="ghost"
                    className="rounded-start rounded-pill border-start-0"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <SVGEyeClose />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {props.errors.newPassword}
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-4">
                  <Form.Control
                    id="newPasswordConfirm"
                    type={showNewPasswordConfirm ? 'text' : 'password'}
                    placeholder="Repeat Password"
                    className="bg-white rounded-end rounded-pill border-end-0 px-3 py-2 fs-7"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    isInvalid={!!props.errors.newPasswordConfirm}
                    value={props.values.newPasswordConfirm}
                  />
                  <Button
                    variant="ghost"
                    className="rounded-start rounded-pill border-start-0"
                    onClick={() =>
                      setShowNewPasswordConfirm(!showNewPasswordConfirm)
                    }
                  >
                    <SVGEyeClose />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {props.errors.newPasswordConfirm}
                  </Form.Control.Feedback>
                </InputGroup>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="secondary"
                    type="submit"
                    className="rounded-pill"
                  >
                    Change password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  )
}

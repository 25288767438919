import { Outlet } from 'react-router-dom'
import { TermsConditionsProvider } from '../../utils/contexts/TermsContextContext/termsConditionsProvider'

export const CheckoutRoot = () => {
  return (
    <TermsConditionsProvider>
      <Outlet />
    </TermsConditionsProvider>
  )
}

/* eslint-disable react/no-unescaped-entities */

import Container from 'react-bootstrap/Container'
import { PageTitle } from '../../components'

export const PrivacyPolicy = () => {
  return (
    <>
      <PageTitle title="Privacy Policy" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto">
          <br />
          We, at RL Events Ltd value the trust our customers and users place on
          us. That's why RL Events Ltd perseveres upon the highest values for
          secure transactions and privacy of customer information.
          <br />
          <br />
          Kindly read the following statement to understand our information
          assembly and dissemination patterns.
          <br />
          <br />
          Note: Our privacy policy is subject to change at any time without
          notice. To make sure you are aware of any changes, please review this
          policy periodically.
          <br />
          <br />
          By visiting the RL Events Ltd website you agree to be bound by the
          terms and conditions of this Privacy Policy. In case you are not in
          agreement, you are requested not to use or access our Website. By mere
          use of the Website, you expressly consent to our use and disclosure of
          your personal information in accordance with this Privacy Policy. This
          Privacy Policy is incorporated into and subject to the Terms of Use.
          <br />
          <br />
          <strong>
            1. Accumulation of Personally Identifiable Information and other
            Information
          </strong>
          <br />
          When you use our website, we collect and store your personal
          information which is provided by you from time to time. Our primary
          goal in doing this is to provide you a safe, resourceful, smooth and
          customised experience while visiting our website. This allows us to
          provide services and features that most likely meet your requirements,
          and to customise our Website to make your experience safer and easier
          every time you visit. More importantly, while doing so, we collect
          personal information from you that we consider necessary for achieving
          this principle.
          <br />
          <br />
          In general, you can browse the Website without telling us who you are
          or revealing any personal information about yourself. Once you give us
          your personal information, you are not anonymous to us. Where
          possible, we indicate which fields are required and which fields are
          optional. You always have the option to not provide information by
          choosing not to use a particular service or feature on the Website. We
          may automatically track certain information about you based upon your
          behaviour on our Website. We use this information to do internal
          research on our users' demographics, interests, and behaviour to
          better understand, protect and serve our users in the future. This
          information is compiled and analysed on an aggregated basis. This
          information may include the URL that you just came from (whether this
          URL is on our Website or not), which URL you next go to (whether this
          URL is on our Website or not), your computer browser information, and
          your IP address.
          <br />
          <br />
          We use data collection devices such as “cookies” on certain pages of
          the Website to help analyse our web page flow, measure promotional
          effectiveness, and promote reliance and safety. “Cookies” are small
          files placed on your hard drive that assist us in providing our
          services. We offer certain features that are only available through
          the use of a “cookie”.
          <br />
          <br />
          (What does Cookies means: A “cookie” is a small piece of information
          stored by a web server in a web browser so it can be later read back
          from that browser. Cookies are useful for enabling the browser to
          remember information specific to a given user. We place both permanent
          and temporary cookies in your computer’s hard drive. The cookies do
          not contain any of your personally identifiable information.)
          <br />
          <br />
          We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          “session cookies,” meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline our cookies if your browser permits, although in that case,
          you may not be able to use certain features on the Website and you may
          be required to re-enter your password more frequently during a
          session.
          <br />
          <br />
          Additionally, you may encounter “cookies” or other similar devices on
          certain pages of the Website that are placed by third parties. We do
          not control the use of cookies by third parties. If you choose to buy
          on the Website, we collect information about your buying behaviour.
          <br />
          <br />
          If you transact with us, we collect some additional information, such
          as a billing address, a credit/debit card number and a credit/debit
          card expiration date and/ or other payment instrument details and
          tracking information from cheques or money orders.
          <br />
          <br />
          If you choose to post messages on our message boards, chat rooms or
          other message areas or leave feedback, we will collect the information
          you provide to us. We retain this information as necessary to resolve
          disputes, provide customer support and troubleshoot problems as
          permitted by law. If you send us personal correspondence, such as
          emails or letters, or if other users or third parties send us
          correspondence about your activities or postings on the Website, we
          may collect such information into a file specific to you.
          <br />
          <br />
          We collect personally identifiable information (email address, name,
          phone number, credit card/debit card / other payment instrument
          details, etc.) from you when you set up a free account with us. While
          you can browse some sections of our Website without being a registered
          member, certain activities (such as placing an order) do require
          registration. We do use your contact information to send you offers
          based on your previous orders and your interests.
          <br />
          <br />
          <strong>
            2. Use of Demographic / Profile Data / Your Information
          </strong>
          <br />
          We use personal information to provide the services you request. To
          the extent we use your personal information to market to you, we will
          provide you with the ability to opt-out of such uses. We use your
          personal information to resolve disputes; troubleshoot problems; help
          promote a safe service; collect money; measure consumer interest in
          our products and services, inform you about online and offline offers,
          products, services, and updates; customise your experience; detect and
          protect us against error, fraud and other criminal activity; enforce
          our terms and conditions; and as otherwise described to you at the
          time of collection.
          <br />
          <br />
          In our efforts to continually improve our product and service
          offerings, we collect and analyse demographic and profile data about
          our users' activity on our Website.
          <br />
          <br />
          We identify and use your IP address to help diagnose problems with our
          server and to administer our Website. Your IP address is also used to
          help identify you and to gather broad demographic information.
          <br />
          <br />
          We will occasionally ask you to complete optional online surveys.
          These surveys may ask you for contact information and demographic
          information (like zip code, age, or income level). We use this data to
          tailor your experience at our Website, providing you with content that
          we think you might be interested in and displaying the content
          according to your preferences.
          <br />
          <br />
          <strong>3. Sharing of personal information</strong>
          <br />
          We may share personal information with our other corporate entities
          and affiliates to help detect and prevent identity theft, fraud and
          other potentially illegal acts; correlate related or multiple accounts
          to prevent abuse of our services, and to facilitate joint or
          co-branded services that you request where such services are provided
          by more than one corporate entity. Those entities and affiliates may
          not market to you as a result of such sharing unless you explicitly
          opt-in.
          <br />
          <br />
          We may disclose personal information if required to do so by law or in
          the good faith belief that such disclosure is reasonably necessary to
          respond to subpoenas, court orders, or other legal process. We may
          disclose personal information to law enforcement offices, third party
          rights owners, or others, in the good faith belief that such
          disclosure is reasonably necessary to: enforce our Terms or Privacy
          Policy; respond to claims that an advertisement, posting or other
          content violates the rights of a third party; or protect the rights,
          property or personal safety of our users or the general public.
          <br />
          <br />
          We and our affiliates will share/sell your personal information with
          another business entity should we (or our assets) plan to merge with,
          or be acquired by that business entity, or reorganisation,
          amalgamation, restructuring of business. Should such a transaction
          occur that another business entity (or the new combined entity) will
          be required to follow this privacy policy in connection to your
          personal information?
          <br />
          <br />
          <strong>4. Links to Other Sites</strong>
          <br />
          Our Website links to other websites that may collect personally
          identifiable information about you redlotusevents.com is not
          responsible for the privacy practices or the content of those linked
          websites.
          <br />
          <br />
          <strong>5. Security Precautions</strong>
          <br />
          Our Website has stringent security measures in place to protect the
          loss, misuse, and alteration of the information under our control.
          Whenever you change or access your account information, we offer the
          use of a secure server. Once your information is in our possession, we
          adhere to strict security guidelines, protecting it against
          unauthorised access
          <br />
          <br />
          <strong>6. Choice/ Cop out</strong>
          <br />
          We provide all users with the opportunity to opt-out of receiving
          non-essential (promotional, marketing-related) communications from us
          on behalf of our partners, and from us in general, after setting up an
          account.
          <br />
          <br />
          If you want to remove your contact information from all our lists and
          newsletters, please visit unsubscribe
          <br />
          <br />
          <strong>7. Advertisements</strong>
          <br />
          We use third-party advertising companies to serve ads when you visit
          our Website. These companies may use information (not including your
          name, address, email address, or telephone number) about your visits
          to this and other websites in order to provide advertisements about
          goods and services of interest to you.
          <br />
          <br />
          <strong>8. Your Consent</strong>
          <br />
          By using the Website and/ or by providing your information, you
          consent to the collection and use of the information you disclose on
          the Website in accordance with this Privacy Policy, including but not
          limited to your consent for sharing your information as per this
          privacy policy.
          <br />
          <br />
          If we decide to change our privacy policy, we will post those changes
          on this page so that you are always aware of what information we
          collect, how we use it, and under what circumstances we disclose it.
        </p>
      </Container>
    </>
  )
}

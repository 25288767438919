import { Fragment, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useModal } from '@ebay/nice-modal-react'

import { ProductType, TicketType } from '../../../types'
import {
  useAuthentication,
  useCart,
  useDeviceType,
  useLoading,
} from '../../../utils/contexts'
import { PageTitle, PaypalButtonsWrapper } from '../../../components'
import {
  CartItemType,
  CartItemTypeEnum,
} from '../../../utils/contexts/CartContext/cartContext.type'
import './checkoutRemainingPayment.scss'
import { getQuantityPrice } from '../../../utils/helpers'
import { AuthModal } from '../../../components/AuthModal'
import { OrderType, PaymentInfoType } from './checkoutRemainingPayment.type'

type NewCartType = CartItemType & {
  displayPrice: string
  displayQuantity: number
}

type FilteredListType = {
  [key: string]: NewCartType[]
}

type CheckoutProps = {
  applyVat?: boolean
}

const PaymentOptions = [
  { name: 'Full Payment', value: '1' },
  { name: 'Partial Payment', value: '2' },
]

export const CheckoutRemainingPayment = ({
  applyVat = true,
}: CheckoutProps) => {
  const { shippingCost } = useCart()
  const { token, logout } = useAuthentication()

  const location = useLocation()
  const authModal = useModal(AuthModal)
  const { isMobile } = useDeviceType()
  const [searchParams] = useSearchParams()
  const { setIsLoading } = useLoading()

  const [sortedItems, setSortedItems] = useState<FilteredListType>({})
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [remainingAmount, setRemainingAmout] = useState(0)

  const setCartItems = (cartItems: CartItemType[]) => {
    if (cartItems) {
      setSubTotalPrice(() => 0)
      const filteredList: FilteredListType = {}

      cartItems.forEach((cartItem) => {
        setSubTotalPrice(
          (subTotalPrice) =>
            subTotalPrice +
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        )

        const { newQuantity, newPricePerUnit } = getQuantityPrice(cartItem)

        const localCartItem: NewCartType = {
          ...cartItem,
          displayPrice: `${newPricePerUnit}`,
          displayQuantity: newQuantity,
        }

        let key = ''

        if (cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
          key = (cartItem.item as TicketType).event?.name || 'Tickets'
        } else if (
          (cartItem.item as ProductType)?.category?.name === 'Printify'
        ) {
          key = 'Merchandise'
        } else {
          key = 'Stalls'
        }

        if (filteredList[key]) {
          filteredList[key].push(localCartItem)
        } else {
          filteredList[key] = [localCartItem]
        }
      })
      setSortedItems({ ...filteredList })
    }
  }

  const fetchPaymentInfo = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get<PaymentInfoType>(
        `/payments/${searchParams.get('poi')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (data) {
        setRemainingAmout(parseFloat(`${data.amount}`))
      }
    } catch {
      toast.error('Error fetching payment information. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchOrder = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get<{ data: OrderType }>(
        `/orders/${searchParams.get('oi')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setCartItems(data.data.items)
    } catch {
      toast.error('Error fetching your order. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (token.length > 0) {
      void fetchOrder()
      void fetchPaymentInfo()
    }
  }, [token])

  useEffect(() => {
    void logout()
    void authModal.show()
  }, [])

  useEffect(() => {
    let total = 0

    if (applyVat) {
      total += subTotalPrice * 1.2
    } else {
      total += subTotalPrice
    }

    setTotalPrice(Math.round(total * 100) / 100)
  }, [applyVat, subTotalPrice])

  return (
    <>
      <PageTitle title="Checkout - Partial Payment" />
      <Container className="mt-4 mt-xl-5 py-4 pt-xl-5 checkout">
        <Row>
          <Col md={2} lg={3} />
          <Col md={8} lg={6}>
            <Card className="border-2 border-secondary rounded-3 p-3">
              <Row className="border-bottom border-dark pb-2 mx-0">
                <Col
                  xs={4}
                  sm={4}
                  md={5}
                  className={`p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Item
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  className={`text-center p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Price
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Quantity
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Total
                </Col>
              </Row>
              {Object.entries(sortedItems).map(([key, cartItems]) => (
                <Fragment key={key}>
                  <Row className="mx-0 mt-2">
                    <Col md={12} className={`${isMobile ? 'p-0' : ''}`}>
                      <p className={`fw-bold ${isMobile ? 'fs-6' : 'fs-5'}`}>
                        {key}
                      </p>
                    </Col>
                  </Row>
                  <Row className="border-dark border-bottom border-opacity-25 mb-3 pb-2 mx-0">
                    {cartItems.map((cartItem) => (
                      <Fragment key={cartItem.id}>
                        <Col
                          xs={4}
                          sm={4}
                          md={5}
                          className={`fs-8 d-flex align-items-center pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          {cartItem.item.name}
                        </Col>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="fw-bolder d-flex align-items-center justify-content-center pb-2"
                        >
                          £{cartItem.displayPrice}
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={2}
                          className="d-flex align-items-center justify-content-center pb-2"
                        >
                          <p className="fs-7">{cartItem.displayQuantity}</p>
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={3}
                          className={`d-flex align-items-center justify-content-end pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          <span className="fw-bolder">
                            £{cartItem.price_per_unit * cartItem.quantity}
                          </span>
                        </Col>
                      </Fragment>
                    ))}
                  </Row>
                </Fragment>
              ))}
            </Card>
          </Col>
          <Col md={2} lg={3} />
        </Row>
        <Row>
          <Col md={2} lg={3} />
          <Col md={8} lg={6}>
            <Card className="border-2 border-secondary rounded-3 p-2 mt-5">
              <Row className="mx-0 mt-4 pb-2">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Subtotal
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {subTotalPrice}
                </Col>
              </Row>
              {applyVat || location.state?.applyVat ? (
                <Row className="mx-0 pb-2">
                  <Col xs={8} sm={8} md={8} className="fw-bold">
                    VAT 20%
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £{' '}
                    {Math.round(
                      (subTotalPrice + (shippingCost?.standard || 0)) *
                        0.2 *
                        100
                    ) / 100}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mx-0 mt-4 mb-2 pt-3 border-top border-dark border-opacity-25">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Total
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {totalPrice}
                </Col>
              </Row>
              <Row className="mx-0 mb-2 pt-1">
                <Col xs={8} sm={8} md={8} className="text-success fw-bold">
                  Paid Amount - 60%
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  className="text-success text-end fw-semibold"
                >
                  £ {totalPrice - remainingAmount}
                </Col>
              </Row>
              <Row className="mx-0 mb-2 pt-1">
                <Col xs={8} sm={8} md={8} className="text-danger fw-bold">
                  Remaining Amount - 40%
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  className="text-danger text-end fw-semibold"
                >
                  £ {remainingAmount}
                </Col>
              </Row>
              <Row className="mx-0 mt-4 mb-2">
                <Col className="d-flex justify-content-center">
                  <PaypalButtonsWrapper
                    isPartialPayment={false}
                    collectRemainingPayment
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={2} lg={3} />
        </Row>
      </Container>
    </>
  )
}

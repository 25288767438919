import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SVGSuccess } from '../../assets/icons/success.svg'
import { ReactComponent as SVGClose } from '../../assets/icons/close.svg'
import { useDeviceType } from '../../utils/contexts'

export const OrderSuccessModal = NiceModal.create(
  ({ orderId }: { orderId: string }) => {
    const modal = useModal()
    const navigate = useNavigate()
    const { isMobile } = useDeviceType()

    const closeModal = async () => {
      await modal.hide()
      navigate('/', { replace: true })
    }

    return (
      <Modal
        {...bootstrapDialog(modal)}
        contentClassName="border border-2 rounded-5 border-secondary"
        backdropClassName="bg-white"
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody className="px-4 py-3 mb-4">
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="ghost" onClick={() => void closeModal()}>
                <SVGClose />
              </Button>
            </Col>
          </Row>
          <div className="w-50 w-md-100 mx-auto">
            <Row>
              <Col className="d-flex justify-content-center">
                <SVGSuccess />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex justify-content-center">
                <p
                  className={`text-center fw-bold ${
                    isMobile ? 'fs-6' : 'fs-4'
                  }`}
                >
                  Purchase Successful
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center">
                <p className={`text-center ${isMobile ? 'fs-7' : 'fs-5'}`}>
                  Your Order was successfully placed.
                </p>
                <p className={`text-center ${isMobile ? 'fs-7' : 'fs-5'}`}>
                  Order ID: <span className="fw-semibold">{orderId}</span>
                </p>
                <p className={`text-center ${isMobile ? 'fs-7' : 'fs-5'}`}>
                  Ticket information has been sent on your registered email.
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="d-flex flex-column justify-content-center">
                <Button variant="primary" onClick={() => void closeModal()}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    )
  }
)

import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'

import { QuantityPickerProps } from './quantityPicker.types'
import './quantityPicker.scss'

export const QuantityPicker: React.FC<QuantityPickerProps> = ({
  min,
  max,
  initialValue = 1,
  onChange,
  wrapperClass = '',
}) => {
  const [quantity, setQuantity] = useState<number>(
    initialValue >= min ? initialValue : min
  )
  const [disableIncrease, setDisableIncrease] = useState(false)
  const [disableDecrease, setDisableDecrease] = useState(false)

  useEffect(() => {
    if (quantity > max) {
      setDisableIncrease(true)
      setQuantity(max)
    } else {
      setDisableIncrease(false)
    }
    if (min > 0) {
      if (quantity < min) {
        setDisableDecrease(true)
        setQuantity(min)
      } else {
        setDisableDecrease(false)
      }
    }
  }, [quantity, max])

  const onIncrease = () => {
    const newQuantity = quantity + 1

    if (newQuantity > max) {
      setDisableIncrease(true)
      setQuantity(max)
    } else {
      setDisableIncrease(false)
      setDisableDecrease(false)
      setQuantity(newQuantity)
      onChange(newQuantity)
    }
  }

  const onDecrease = () => {
    const newQuantity = quantity - 1

    if (newQuantity < min) {
      setDisableDecrease(true)
      setQuantity(min)
    } else {
      setDisableIncrease(false)
      setDisableDecrease(false)
      setQuantity(newQuantity)
      onChange(newQuantity)
    }
  }

  return (
    <div
      className={`quantity-picker border border-dark rounded rounded-pill d-flex justify-content-center align-items-center text-black overflow-hidden ${wrapperClass}`}
    >
      <Button
        disabled={disableDecrease}
        onClick={onDecrease}
        variant="outline-light"
        className="text-dark border-0 p-0 fs-5 lh-1"
      >
        -
      </Button>
      <p className="mb-0 text-center">{quantity}</p>
      <Button
        disabled={disableIncrease}
        onClick={onIncrease}
        variant="outline-light"
        className="text-dark border-0 p-0 fs-5 lh-1"
      >
        +
      </Button>
    </div>
  )
}

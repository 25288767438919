import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Moment from 'react-moment'

import { MyOrdersLocal } from '../../../pages/Account/account.type'

export const TicketsDesktop = ({
  orders,
  sendTickets,
}: {
  orders: MyOrdersLocal[]
  sendTickets: (orderId: string) => void
}) => {
  return orders && orders.length > 0 ? (
    <>
      <Row className="pb-3 border-2 border-bottom border-dark mb-3">
        <Col sm={4} className="fw-bold">
          Ticket
        </Col>
        <Col sm={4} className="fw-bold">
          Date of Purchase
        </Col>
        <Col sm={4} className="fw-bold">
          Send to your email
        </Col>
      </Row>
      {orders.map((order) => (
        <Row
          key={`${order.id}_${order.created_at}_${order.itemId}`}
          className="pb-2 mb-2 d-flex align-items-center border-bottom"
        >
          <Col sm={4}>{order.itemName}</Col>
          <Col sm={4}>
            <Moment format="Do MMMM YYYY">{order.created_at}</Moment>
          </Col>
          <Col sm={4}>
            <Button
              variant="primary"
              className="rounded-pill"
              onClick={() => sendTickets(`${order.id}`)}
            >
              Send Tickets
            </Button>
          </Col>
        </Row>
      ))}
    </>
  ) : (
    <p>You have not purchased any tickets</p>
  )
}

import { ReactNode, useMemo, useState } from 'react'

import { TermsConditionsContext } from './termsContext'

export const TermsConditionsProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const [accepted, setIsAccepted] = useState(false)

  const value = useMemo(
    () => ({
      accepted,
      setIsAccepted,
    }),
    [accepted]
  )

  return (
    <TermsConditionsContext.Provider value={value}>
      {children}
    </TermsConditionsContext.Provider>
  )
}

import { useCallback } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Formik, FormikState } from 'formik'
import { string, object } from 'yup'
import { toast } from 'react-toastify'
import axios from 'axios'

import { PageTitle } from '../../components'

const schema = object().shape({
  name: string().required('Fullname is a required field'),
  email: string()
    .required('Email is a required field')
    .email('Enter correct email address'),
  phone: string(),
  message: string(),
})

type SendEmailProps = {
  values: {
    name: string
    phone: string
    email: string
    message: string
  }
  resetForm: (
    nextState?:
      | Partial<
          FormikState<{
            name: string
            phone: string
            email: string
            message: string
          }>
        >
      | undefined
  ) => void
}

export const ContactUs = () => {
  const handleSendEmail = useCallback(async (props: SendEmailProps) => {
    try {
      const { data } = await axios.post<{ success: boolean }>(
        '/contact-us',
        JSON.stringify({
          ...props.values,
        })
      )

      if (data.success) {
        toast.success(
          'Thank you for getting in touch! We will be sure to get back to you soon.'
        )
        props.resetForm()
      }
    } catch {
      toast.error(
        'Ohno! Seems we were not able to receive your message. Please try again later, or email us on info@redlotusevents.com'
      )
    }
  }, [])

  return (
    <>
      <PageTitle title="Get in Touch" />
      <Container>
        <Row className="my-4 pt-4">
          <Col md={1} className="d-none d-md-block" />
          <Col md={3} className="mb-3 mb-md-0">
            <div className="d-flex flex-column p-4 rounded-5 bg-primary text-light">
              <h3 className="fs-4 fw-bold">Get In Touch</h3>
              <p>
                We’d love to hear from you. Our team is always ready to help.
              </p>
              <div className="mt-3 mt-md-5 text-md-left">
                <a
                  href="mailto:info@redlotusevents.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-decoration-none align-middle"
                >
                  Email us at <br />
                  <span className="fw-bold">info@redlotusevents.com</span>
                </a>
              </div>
            </div>
          </Col>
          <Col md={7}>
            <div className="d-flex flex-column rounded-5 border border-2 border-primary p-4">
              <h3 className="fw-bold fs-4">How can we help</h3>
              <Formik
                validationSchema={schema}
                validateOnChange={false}
                validateOnMount={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                  void handleSendEmail({ values, resetForm })
                }}
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  message: '',
                }}
              >
                {(props) => (
                  <Form
                    className="mt-3"
                    noValidate
                    onSubmit={props.handleSubmit}
                  >
                    <Form.Group className="mb-4">
                      <Form.Control
                        id="name"
                        type="text"
                        placeholder="Enter your full name"
                        className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.errors.name}
                        value={props.values.name}
                        autoComplete="name"
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        id="email"
                        type="email"
                        placeholder="Enter your email address"
                        className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.errors.email}
                        value={props.values.email}
                        autoComplete="email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        id="phone"
                        type="phone"
                        placeholder="Phone No."
                        className="bg-white rounded-pill border-secondary px-3 py-2 fs-7"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.errors.phone}
                        value={props.values.phone}
                        autoComplete="tel"
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        id="message"
                        type="message"
                        as="textarea"
                        rows={4}
                        placeholder="Your Message"
                        className="bg-white rounded-5 border-secondary px-3 py-2 fs-7"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.errors.message}
                        value={props.values.message}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="primary"
                        type="submit"
                        className="rounded-pill"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
          <Col md={1} className="d-none d-md-block" />
        </Row>
      </Container>
    </>
  )
}

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { BasketSummary } from '../../BasketSummary'

type MenuProps = {
  showCart: boolean
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>
}

export const Cart = ({ showCart, setShowCart }: MenuProps) => {
  return (
    <Offcanvas
      show={showCart}
      onHide={() => setShowCart(false)}
      placement="end"
    >
      <Offcanvas.Body>
        <div className="w-100 h-100 d-flex flex-column align-items-md-center justify-content-md-center">
          <Row className="w-100 mb-md-4 align-items-center">
            <Col>
              <p className="text-white fs-4 fw-bold m-0">Your Cart</p>
            </Col>
            <Col className="d-flex justify-content-end p-0">
              <div
                className="cursor-pointer w-fit-content fs-3 align-right text-white px-3 py-1"
                onClick={() => setShowCart(false)}
                onKeyUp={() => setShowCart(false)}
                role="button"
                tabIndex={0}
              >
                X
              </div>
            </Col>
          </Row>
          <Row className="w-100">
            <Col>
              <BasketSummary isCartOverlay />
            </Col>
          </Row>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

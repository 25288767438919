import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCart } from '../../utils/contexts'
import './basketSummary.scss'
import { CartItemTypeEnum } from '../../utils/contexts/CartContext/cartContext.type'
import {
  EventType,
  PrintifyProductType,
  ProductType,
  TicketType,
} from '../../types'
import {
  formatPrice,
  getQuantityPrice,
  sortObjectByKey,
} from '../../utils/helpers'
import { ReactComponent as SVGBin } from '../../assets/icons/bin.svg'
import {
  ACCOMPANYING_ADULT_TICKET_IDS,
  KIDS_BELOW_TWO_YRS_TICKET_IDS,
  KIDS_EVENT_IDS,
  KIDS_TICKET_IDS,
  NORMAL_EVENT_IDS,
} from '../../utils/constants'

type ListItem = {
  id: number | string
  name: string
  price: number
  quantity: number
  item: TicketType | ProductType | PrintifyProductType
  eventId?: number
  seatIds?: string[]
}

type FilteredListType = {
  [key: string]: ListItem[]
}

type BasketSummaryProps = {
  isCartOverlay?: boolean
  hideCheckoutButton?: boolean
}

const isDeleteButtonDisabled = (ticket: ListItem): boolean => {
  if (
    KIDS_EVENT_IDS?.includes(ticket.eventId || 0) &&
    Object.values(ACCOMPANYING_ADULT_TICKET_IDS).find((adultTicketIds) =>
      adultTicketIds?.includes(ticket.item.id)
    )
  ) {
    return true
  }
  return false
}

export const BasketSummary = ({
  isCartOverlay = false,
  hideCheckoutButton = false,
}: BasketSummaryProps) => {
  const {
    cart,
    deleteCartItem,
    isPrintifyProductInCart,
    deleteCart,
    updateItem,
  } = useCart()
  const [sortedItems, setSortedItems] = useState<FilteredListType>({})
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const navigate = useNavigate()
  const location = useLocation()

  const deleteItem = useCallback((cartItem: ListItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    void deleteCartItem(cartItem.item)
  }, [])

  const onUpdateCartItem = (
    quantity: number,
    ticket: TicketType,
    event?: EventType
  ) => {
    if (quantity === 0) {
      void deleteCartItem(ticket)
    } else {
      void updateItem({
        item: ticket,
        itemType: CartItemTypeEnum.CART_ITEM_TYPE_TICKET,
        event,
        quantity: quantity || 1,
      })
    }
  }

  useEffect(() => {
    if (cart) {
      setTotalPrice(() => 0)
      const filteredList: FilteredListType = {}

      cart.items.forEach((cartItem) => {
        const newPricePerUnit =
          Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity

        const { newQuantity } = getQuantityPrice(cartItem)

        setTotalPrice(
          (totalPrice) => Math.round((totalPrice + newPricePerUnit) * 100) / 100
        )

        let key = ''

        if (cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
          key = (cartItem.item as TicketType).event?.name || 'Tickets'
        } else if (
          (cartItem.item as ProductType).category.name === 'Printify'
        ) {
          key = 'Merchandise'
        } else {
          key = (cartItem.item as ProductType).category.name
        }

        if (filteredList[key]) {
          filteredList[key].push({
            id: cartItem.id,
            name: cartItem.item.name,
            price: newPricePerUnit,
            quantity: newQuantity,
            item: cartItem.item,
            eventId: (cartItem.item as TicketType).event?.id,
            seatIds: cartItem.object_id,
          })
        } else {
          filteredList[key] = [
            {
              id: cartItem.id,
              name: cartItem.item.name,
              price: newPricePerUnit,
              quantity: newQuantity,
              item: cartItem.item,
              eventId: (cartItem.item as TicketType).event?.id,
              seatIds: cartItem.object_id,
            },
          ]
        }

        const eventId = (cartItem.item as TicketType).event?.id || 0

        // do NOT remove accompnaying parent ticket if we are on /tickets page. Because the page does it by itself.
        if (!location.pathname?.includes('/tickets')) {
          const adultTicketForCurrentEvent = cart.items.find((cartItemAdults) =>
            ACCOMPANYING_ADULT_TICKET_IDS[eventId]?.includes(
              cartItemAdults.item.id
            )
          )

          // Interlinking Child and Adult Tickets for Kids Garba
          if (KIDS_EVENT_IDS?.includes(eventId)) {
            // const kidTicketForCurrentEvent = cart.items.find((cartItemKids) =>
            //   KIDS_TICKET_IDS[eventId]?.includes(cartItemKids.item.id)
            // )

            const kidTicketForCurrentEvent = cart.items.filter((cartItem) =>
              KIDS_TICKET_IDS[eventId]?.includes(cartItem.item.id)
            )

            let quantityOfKidsTickets = 0
            if (kidTicketForCurrentEvent?.length > 0) {
              kidTicketForCurrentEvent?.forEach((kidTicket) => {
                quantityOfKidsTickets += kidTicket.quantity
              })
            }

            // If number of Kid ticket is less than number of accompanying adult, update quantity of accompanying adult
            if (
              kidTicketForCurrentEvent.length > 0 &&
              adultTicketForCurrentEvent
            ) {
              if (
                adultTicketForCurrentEvent.item.id ===
                  (cartItem.item as TicketType).id &&
                adultTicketForCurrentEvent.quantity > quantityOfKidsTickets
              ) {
                onUpdateCartItem(
                  quantityOfKidsTickets,
                  cartItem.item as TicketType,
                  (cartItem.item as TicketType).event
                )
              }
            }

            if (
              adultTicketForCurrentEvent &&
              kidTicketForCurrentEvent.length === 0
            ) {
              deleteItem({
                id: adultTicketForCurrentEvent.id,
                name: adultTicketForCurrentEvent.item.name,
                price: adultTicketForCurrentEvent.price_per_unit,
                quantity: adultTicketForCurrentEvent.quantity,
                item: adultTicketForCurrentEvent.item as TicketType,
              })
            }
          }

          // If Adult ticket is removed from current event, remove child ticket as well
          if (NORMAL_EVENT_IDS?.includes(eventId)) {
            const kidTicketForCurrentEvent = cart.items.find((cartItem) =>
              KIDS_BELOW_TWO_YRS_TICKET_IDS[eventId]?.includes(cartItem.item.id)
            )

            if (
              kidTicketForCurrentEvent &&
              !adultTicketForCurrentEvent &&
              kidTicketForCurrentEvent.item.id === cartItem.item.id
            ) {
              deleteItem({
                id: kidTicketForCurrentEvent.id,
                name: kidTicketForCurrentEvent.item.name,
                price: kidTicketForCurrentEvent.price_per_unit,
                quantity: kidTicketForCurrentEvent.quantity,
                item: kidTicketForCurrentEvent.item,
              })
            }
          }
        }
      })

      // This will sort the list based on Name of the ticket
      const sortObject = sortObjectByKey<FilteredListType>(filteredList)

      setSortedItems({ ...sortObject })
    }
  }, [cart, location])

  const getCheckoutLink: string = useMemo(() => {
    if (cart) {
      const isTicketsInCart = cart.items.some(
        (item) => item.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
      )

      if (isPrintifyProductInCart) {
        return '/checkout/shipping-address'
      }

      if (isTicketsInCart) {
        return '/checkout/overview'
      }

      return '/exhibitor-shop/checkout'
    }

    return ''
  }, [cart])

  const checkClick = () => {
    navigate(getCheckoutLink)
  }

  return (
    <div
      className={`mt-4 ${
        isCartOverlay ? 'text-white' : 'position-sticky summary-wrapper'
      }`}
    >
      {isCartOverlay ? <></> : <p className="fw-bold">Total</p>}
      <Row
        className={`${
          isCartOverlay ? 'border-light' : 'border-dark'
        } border-bottom mb-3`}
      >
        <Col xs={6} sm={6} md={6}>
          {isCartOverlay ? 'Product' : 'Ticket'}
        </Col>
        <Col xs={3} sm={3} md={3} className="text-center">
          QTY
        </Col>
        <Col
          xs={isCartOverlay ? 2 : 3}
          sm={isCartOverlay ? 2 : 3}
          md={isCartOverlay ? 2 : 3}
          className="text-end"
        >
          Price
        </Col>
        {isCartOverlay ? <Col xs={1} sm={1} md={1} /> : <></>}
      </Row>
      {Object.entries(sortedItems).map(([event, tickets]) => (
        <Row
          key={event}
          className={`${
            isCartOverlay ? 'border-light' : 'border-dark'
          } border-bottom border-opacity-25 mb-3 pb-2`}
        >
          <Col md={12}>
            <p className="fw-bold mb-1">{event}</p>
          </Col>
          {tickets.map((ticket) => (
            <Fragment key={ticket.id}>
              <Col
                xs={6}
                sm={6}
                md={6}
                className="fs-8 align-items-center d-flex"
              >
                {ticket.name}
                {ticket.seatIds ? ` (${ticket.seatIds.join(', ')})` : ''}
              </Col>
              <Col
                xs={3}
                sm={3}
                md={3}
                className="fs-8 justify-content-center align-items-center d-flex"
              >
                {ticket.quantity}
              </Col>
              <Col
                md={isCartOverlay ? 2 : 3}
                xs={isCartOverlay ? 2 : 3}
                sm={isCartOverlay ? 2 : 3}
                className="fs-8 fw-bold align-items-center d-flex justify-content-end"
              >
                £{formatPrice(ticket.price)}
              </Col>
              {isCartOverlay ? (
                <Col xs={1} sm={1} md={1} className="align-items-center d-flex">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="p-0 border-0"
                    onClick={() => deleteItem(ticket)}
                    disabled={isDeleteButtonDisabled(ticket)}
                  >
                    <SVGBin />
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </Row>
      ))}
      <Row className="mb-3">
        <Col xs={6} sm={6} md={6} />
        <Col xs={3} sm={3} md={3}>
          Total:{' '}
        </Col>
        <Col xs={3} sm={3} md={3} className="text-end fw-semibold">
          £{formatPrice(totalPrice)}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-between">
          {isCartOverlay ? (
            <Button
              variant="ghost"
              className="rounded-pill text-light"
              onClick={() => void deleteCart()}
              disabled={cart?.items.length === 0}
            >
              Empty Cart
            </Button>
          ) : (
            <></>
          )}
          {hideCheckoutButton ? (
            <></>
          ) : (
            <Button
              variant={isCartOverlay ? 'outline-light' : 'primary'}
              className="rounded-pill"
              onClick={checkClick}
              disabled={cart?.items.length === 0}
            >
              Checkout
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}

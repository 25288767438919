import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'

import { EventCard, BasketSummary, PageTitle } from '../../components'
import { useFetch } from '../../utils/hooks'
import { MainEvent } from '../../types'
import { useCart } from '../../utils/contexts'
import './tickets.scss'
import { CartItemTypeEnum } from '../../utils/contexts/CartContext/cartContext.type'
import { formatPrice } from '../../utils/helpers'

export const Tickets = () => {
  const { eventSlug } = useParams()

  const { data: mainEventData, error: eventsError } = useFetch<{
    data: MainEvent
  }>(`/events/by-slug/${eventSlug}`)

  const { cart, isPrintifyProductInCart } = useCart()
  const navigate = useNavigate()

  const [totalPrice, setTotalPrice] = useState<number>(0)

  const setCartItems = () => {
    if (cart) {
      setTotalPrice(() => 0)

      cart.items.forEach((cartItem) => {
        setTotalPrice(
          (totalPrice) =>
            totalPrice +
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        )
      })
    }
  }

  useEffect(() => {
    if (cart) {
      setCartItems()
    }
  }, [cart])

  useEffect(() => {
    if (eventsError) {
      toast.error('Error fetching events. Please try again later.')
    }
  }, [eventsError])

  const getCheckoutLink: string = useMemo(() => {
    if (cart) {
      const isTicketsInCart = cart.items.some(
        (item) => item.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
      )

      if (isPrintifyProductInCart) {
        return '/checkout/shipping-address'
      }

      if (isTicketsInCart) {
        return '/checkout/overview'
      }

      return '/exhibitor-shop/checkout'
    }

    return ''
  }, [cart])

  const checkClick = () => {
    navigate(getCheckoutLink)
  }

  return (
    <>
      <PageTitle title={mainEventData?.data.name ?? 'Ticket Booking'} />
      <Container>
        <Row>
          <Col xl={1} className="d-none d-xl-block" />
          <Col lg={8} xl={7}>
            {mainEventData?.data.sub_events.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </Col>
          <Col lg={4} xl={3} className="mb-3 mb-lg-0">
            <BasketSummary />
          </Col>
          <Col xl={1} className="d-none d-xl-block" />
        </Row>
        <Row className="subtotal d-flex d-lg-none position-fixed p-3 bg-white align-items-center">
          <Col xs={6} sm={6} md={0}>
            Subtotal:{' '}
            <span className="fw-semibold">£{formatPrice(totalPrice)}</span>
          </Col>
          <Col xs={6} sm={6} md={0} className="text-end">
            <Button
              variant="primary"
              className="rounded-pill"
              onClick={checkClick}
              disabled={cart?.items.length === 0}
            >
              Checkout
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

import Container from 'react-bootstrap/Container'
import { PageTitle } from '../../components'

export const Faq = () => {
  return (
    <>
      <PageTitle title="FAQ's" />
      <Container>
        <p className="my-4 w-75 w-md-100 mx-auto">
          <br />
          <strong>Frequently Asked Questions</strong>
          <br />
          If you have a question specifically related to deliveries or returns,
          please visit the Delivery and Returns and Exchanges page
          <br />
          <br />
          <strong>Can I cancel or change my order?</strong>
          <br />
          We are committed to getting your order to you as soon as
          possible.Therefore, after your order is placed, it immediately goes
          into the fulfilment process, and you are not able to cancel or adjust
          the order.
          <br />
          <br />
          <strong>
            Can I change the shipping address once my order has been submitted?
          </strong>
          <br />
          In order to process your order and get it to you as quickly as
          possible, we are unable to change the shippingaddress once your order
          has reached us. Please contact us immediately should you have any
          further questions.
          <br />
          <br />
          <strong>
            How do I know when my order ships and how can I track it?
          </strong>
          <br />
          Once your order has been dispatched from our warehouse, you will
          receive a confirmation e-mail to confirm that it&apos;s on its way.
          You will also receive a textor email from DPD including a tracking
          number so that you can track your order.
          <br />
          <br />
          <strong>What types of payment method do you accept?</strong>
          <br />
          We currently accept the following credit cards: Visa, Visa Debit, Visa
          Electron, MasterCardand Maestro UK. You can also choose to pay via
          PayPal.
          <br />
          <br />
          <strong>How do I use a discount / voucher code?</strong>
          <br />
          Discount codes must be entered on the Basket page before proceeding to
          checkoutin order to add your discount. To add a discount code, go to
          My Cart and click on &apos;Apply Discount Code&apos;in the summary box
          to the right of your order. Enter the discount code and click Apply
          Discount. Discount codes are case sensitive and subject to terms and
          conditions.
          <br />
          <br />
          <strong>
            What are your Terms & Conditions for using a discount code?
          </strong>
          <br />
          Discount codes must be applied to the order prior to checkout and are
          not available in conjunction with any other offer unless clearly
          stated.Discount codes cannot be used against sale items unless clearly
          stated.Where an expiry date is not stated, the expiry date is 31 days
          from date of issue.
          <br />
          Discount codes distributed by RANGEELU GUJARAT and its partners are
          eligible for UK & Ireland residents onlyand orders cannot be delivered
          outside of the UK and Ireland.If you have any problems using your
          discount code, please contact our Customer Services Team.We reserve
          the right to withdraw offers at any time without prior notice.
          <br />
          <br />
          <strong>
            What are your Terms & Conditions for competitions and offers?
          </strong>
          <br />
          All Rangeelu Gujarat competitions, offers and discounts have our
          generic terms and conditions applied.
          <br />
          <br />
          <strong>How should I wash my Rangeelu Gujarat Clothing?</strong>
          <br />
          Different items of clothing will have different care and cleaning
          instructions.cPlease refer to the individual item&apos;s care label.
          <br />
          <br />
          <strong>Suspected faulty goods?</strong>
          <br />
          If you suspect a manufacturing defect please return the suspected
          faulty item back to us and we will replace or refund.
          <br />
          <br />
          <strong>Something not working or incorrect?</strong>
          <br />
          If you notice a mistake on the website or find that something is not
          working correctly, please let us know by email so that we can look
          into this as soon as possible.
          <br />
          <br />
          <strong>Need more help?</strong>
          <br />
          Still have questions? Contact our Customer Services Team via email or
          phone
          <br />
        </p>
      </Container>
    </>
  )
}

import { KeyboardEvent, MouseEvent, useEffect } from 'react'
import ClockLoader from 'react-spinners/ClockLoader'

import './loadingIndicator.scss'

export const LoadingIndicator = () => {
  const handleClick = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    if (document && document.getElementById('body')) {
      document.getElementById('body')!.style.overflow = 'hidden'
      document.getElementById('body')!.style.pointerEvents = 'none'
    }

    return () => {
      if (document && document.getElementById('body')) {
        document.getElementById('body')!.style.overflow = 'visible'
        document.getElementById('body')!.style.pointerEvents = 'all'
      }
    }
  }, [])

  return (
    <div
      className="loading-wrapper position-fixed top-0 start-0 end-0 bottom-0 bg-white-50 d-flex justify-content-center align-items-center"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={-1}
    >
      <ClockLoader
        color="#D62C5F"
        loading
        size={150}
        aria-label="Loading Spinner"
      />
    </div>
  )
}
